export const SmallBoxSkeleton = () => {
  return (
    <fieldset className="flex justify-end">
      <div className="grid text-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="animate-pulse rounded-md w-full sm:w-60 border py-3 px-4">
          <div className="mx-auto flex max-w-xs flex-col gap-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-8 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="animate-pulse rounded-md border py-3 px-4">
          <div className="mx-auto flex max-w-xs flex-col gap-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-8 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};
