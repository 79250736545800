export enum Routes {
  HOME = '/',
  FORGOT = '/forgot-password',
  SET_PASSWORD = '/set-password',
  PROTECTION_PLANS = '/plan',
  REPORTS = '/reports',
  REPORTS_CLAIMS = '/reports/claims',
  REPORTS_SALES = '/reports/sales',
  REPORTS_CONTRACTS = '/reports/contracts',
  USER_MANAGEMENT = '/user-management',
  SETTINGS = '/settings',
  TRAINING_PORTAL = '/training-portal',
  FINANCIAL_REPORTS = '/financial-report',
  ANOMALIES = '/anomalies',
  ANOMALIES_PROCESSED = '/anomalies-processed',
}
