import { endpointConstructor } from '../index.js';
const plansEndpoints = () => {
    const { endpoint } = endpointConstructor('/plans');
    return {
        getPlans: endpoint('GET'),
        getPlansForMerchant: endpoint('GET', '/merchant'),
        getPlanById: endpoint('GET', '/:id'),
        getPdfTermsBySku: endpoint('GET', '/terms/:sku'),
    };
};
export default plansEndpoints;
