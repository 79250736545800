import { FC, ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import {
  FileClaimFields,
  StepFourInitialState,
  StepOneInitialState,
  StepThreeInitialState,
  StepTwoInitialState,
} from './constants/facFieldsInitialValues';
import FILE_CLAIM_VALIDATION_SCHEMAS from './constants/facValidationRules';
import useMatchBreakpoint from './helper/useMatchBreakpoint';
import { FileClaimBottom } from './FileClaimBottom';
import { FileClaimHeader } from './FileClaimHeader';
import { checkClaimFormFilled, checkStepValid, FileClaimStep } from '.';

interface FileClaimStepsContainerProps {
  children?: ReactNode;
  formStep: FileClaimStep;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  initialValues?: FileClaimFields;
  handleSubmit: (
    values: FileClaimFields,
    formikHelpers: FormikHelpers<FileClaimFields>,
  ) => void | Promise<any>;
  handleSave: (values: FileClaimFields) => void | Promise<any>;
  isChatBot?: boolean;
  contractNumber: string;
  productName: string;
  requestError?: string;
  isFetching?: boolean;
}

const STEP_NAMES = {
  [FileClaimStep.STEP_ONE]: 'damage_details',
  [FileClaimStep.STEP_TWO]: 'damage_details',
  [FileClaimStep.STEP_THREE]: 'upload_photos',
  [FileClaimStep.STEP_FOUR]: 'verify_contact_info',
  [FileClaimStep.STEP_FIVE]: 'review_your_submission',
};

const FIELDS_INITIAL_STATE = {
  ...StepOneInitialState,
  ...StepTwoInitialState,
  ...StepThreeInitialState,
  ...StepFourInitialState,
};

export const FileClaimStepsContainer: FC<FileClaimStepsContainerProps> = ({
  formStep,
  goToPrevStep,
  children,
  handleSubmit,
  handleSave,
  initialValues = {} as FileClaimFields,
  isChatBot,
  productName,
  contractNumber,
  isFetching,
  requestError,
  goToNextStep,
}) => {
  const isTabletAndAbove = useMatchBreakpoint('48rem');
  const rendered = useRef(false);
  const { t } = useTranslation();
  useEffect(() => {
    isChatBot
      ? document
          .getElementsByClassName('reguard-web-chat__inner')[0]
          .scrollTo(0, 0)
      : window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [formStep, isChatBot]);

  useEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
    }

    return () => {
      rendered.current = false;
    };
  }, [rendered]);

  const handleTitle = () => {
    if (!isTabletAndAbove) {
      if (formStep === FileClaimStep.STEP_FIVE) {
        return `- ${t('summary')}`;
      }

      return '';
    }

    return '';
  };

  return (
    <div
      className={`${
        isChatBot ? 'flex flex-col' : 'flex flex-col cqmd:flex-row md:flex-row'
      }`}
    >
      <div
        className={`${
          isChatBot
            ? 'flex flex-col p-0 min-w-fit'
            : 'flex flex-col p-0 min-w-fit md:w-1/5 md:pr-[8.625rem]'
        }`}
      >
        <h3
          className={`${
            isChatBot
              ? 'text-reguard-indigo s2 pb-2'
              : 'text-reguard-indigo s2 pb-2 sm:text-4xl sm:pb-6 md:text-4xl md:pb-6'
          }`}
        >
          {t('file_a_claim')} {handleTitle()}
        </h3>

        <div
          className={`${
            isChatBot
              ? 'grid grid-cols-1 cqsm:grid-cols-2 cqmd:grid-cols-1'
              : 'grid grid-cols-1 cqsm:grid-cols-2 cqmd:grid-cols-1 sm:grid-cols-2 md:grid-cols-1'
          }`}
        >
          <div
            className={`
                ${
                  isChatBot
                    ? 'text-reguard-indigo b2 pb-3 max-w-[17.938rem]'
                    : 'text-reguard-indigo b2 pb-3 max-w-[17.938rem] sm:s3b sm:pb-0 sm:text md:pb-3 md:max-w-[12.313rem]'
                }
              `}
          >
            {productName}
          </div>
          <div
            className={`${
              isChatBot
                ? 'text-reguard-indigo text-xs text-left'
                : 'text-reguard-indigo text-xs text-left sm:text-base sm:text-right md:text-left md:max-w-[12.313rem]'
            }`}
          >
            {t('contract')} #: {contractNumber}
          </div>
        </div>
      </div>

      <div className="flex justify-center md:justify-start w-full">
        <Formik
          enableReinitialize={isChatBot}
          initialValues={{ ...FIELDS_INITIAL_STATE, ...initialValues }}
          validationSchema={FILE_CLAIM_VALIDATION_SCHEMAS(t)[formStep]}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, errors, handleSubmit, setSubmitting }) => {
            const shouldUserContinue =
              !isSubmitting &&
              checkStepValid(errors, formStep) &&
              checkClaimFormFilled(values, formStep) &&
              !isFetching;

            return (
              <div
                data-step={formStep}
                id="fileaclaim"
                className="flex flex-col w-full max-w-[37.75rem]"
              >
                <FileClaimHeader
                  onSubmit={handleSubmit}
                  formStep={formStep}
                  goBack={goToPrevStep}
                  shouldContinue={shouldUserContinue}
                  stepName={t(STEP_NAMES[formStep]) as string}
                  isChatBot={isChatBot}
                  onContinue={goToNextStep}
                />
                {children}
                <FileClaimBottom
                  onSubmit={handleSubmit}
                  requestError={requestError}
                  isChatBot={isChatBot}
                  formStep={formStep}
                  isSubmitting={isSubmitting || isFetching}
                  shouldContinue={shouldUserContinue}
                  handleSave={async () => {
                    try {
                      setSubmitting(true);
                      await handleSave(values);
                    } finally {
                      rendered.current && setSubmitting(false);
                    }
                  }}
                />
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
