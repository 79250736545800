import type { ComponentPropsWithoutRef } from 'react';

import { ReactComponent as LoaderElipsis } from './assets/loaderEllipsis.svg';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: 'primary' | 'secondary';
  isFetching?: boolean;
  size?: 'small' | 'regular';
}

const baseStyle: any =
  'b1 h-12 p-3 w-full max-w-[17.938rem] text-white inline-flex cursor-pointer justify-center items-center border-[1px] rounded-lg border-solid';

const variantStyles: Record<'primary' | 'secondary', string> = {
  primary: 'border-reguard-wintergreen bg-reguard-wintergreen shadow-button',
  secondary: '!text-reguard-indigo-tint border-reguard-indigo-tint bg-white',
};

const stateStyles: Record<
  'primary' | 'secondary',
  { hover: string; active: string; disabled: string; focus: string }
> = {
  primary: {
    hover: 'hover:bg-reguard-wintergreen-shade hover:border-reguard-violet',
    active:
      'active:bg-reguard-wintergreen-shade active:border-reguard-wintergreen-shade',
    disabled:
      'disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
    focus:
      'focus:bg-reguard-violet-tint focus:text-white focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet',
  },
  secondary: {
    hover:
      'hover:bg-reguard-indigo-tint hover:border-reguard-violet hover:!text-white',
    active: 'active:bg-reguard-pearl-shade active:border-reguard-indigo-tint',
    disabled:
      'disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:!text-reguard-inactive2 disabled:cursor-default',
    focus:
      'focus:bg-[#E6E7EE] focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet',
  },
};

export function Button({
  isFetching = false,
  variant = 'primary',
  className,
  size = 'regular',
  ...props
}: ButtonProps) {
  const btnChildren = isFetching ? (
    <span className="flex flex-row justify-center">
      <LoaderElipsis />
    </span>
  ) : (
    props.children
  );

  const stateStyle: any =
    isFetching || props.disabled
      ? stateStyles[variant].disabled
      : `${stateStyles[variant].hover} ${stateStyles[variant].active} ${stateStyles[variant].disabled} ${stateStyles[variant].focus}`;

  return (
    <button
      className={`tw-cst-pf ${baseStyle} ${
        isFetching
          ? 'border-reguard-inactive1 bg-reguard-inactive1'
          : variantStyles[variant]
      } ${stateStyle} ${size === 'small' ? 'max-w-[12.5rem]' : ''} ${
        className || ''
      }`}
      {...props}
    >
      {btnChildren}
    </button>
  );
}
