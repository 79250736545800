import { useQuery } from 'react-query';
import endpoints from '@reguard/endpoints';
import type { TalentLMSUser } from '@reguard/ui-components';

import { useClient } from './client';

const { getAuthenticatedUser } = endpoints().trainingEndpoints();

function useMyTrainingUser(options = {}) {
  const client = useClient();

  const result = useQuery<TalentLMSUser, Error>({
    queryKey: 'myTrainingUser',
    queryFn: () => client(getAuthenticatedUser.path, {}).then(data => data),
    ...options,
  });

  return {
    ...result,
    data: result.data as TalentLMSUser & { message: string },
  };
}

export { useMyTrainingUser };
