export var ContractStatus;
(function (ContractStatus) {
    ContractStatus["Active"] = "Active";
    ContractStatus["Cancelled"] = "Cancelled";
    ContractStatus["Void"] = "Void";
    ContractStatus["Expired"] = "Expired";
    ContractStatus["Inactive"] = "Inactive";
    ContractStatus["Pending"] = "Pending";
    ContractStatus["Recreated"] = "Recreated";
})(ContractStatus || (ContractStatus = {}));
export var ProcessingStatus;
(function (ProcessingStatus) {
    ProcessingStatus["Processing"] = "PROCESSING";
    ProcessingStatus["Processed"] = "PROCESSED";
    ProcessingStatus["Failed"] = "FAILED";
})(ProcessingStatus || (ProcessingStatus = {}));
export var ContractSource;
(function (ContractSource) {
    ContractSource["Api"] = "Api";
    ContractSource["Recreated"] = "Recreated";
    ContractSource["File"] = "File";
    ContractSource["Merchant"] = "Merchant";
})(ContractSource || (ContractSource = {}));
export var ContractKind;
(function (ContractKind) {
    ContractKind["FPP"] = "FPP";
    ContractKind["SI"] = "SI";
})(ContractKind || (ContractKind = {}));
