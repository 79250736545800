import { endpointConstructor } from '../index.js';
const claimEndpoints = () => {
    const { endpoint } = endpointConstructor('/claim');
    return {
        getClaims: endpoint('GET'),
        getClaimById: endpoint('GET', '/:id'),
        getClaimForFormById: endpoint('GET', '/for-form/:id'),
        saveClaimStep: endpoint('PATCH', '/save-step'),
        addClaimPhotos: endpoint('POST', '/photos'),
        submitClaim: endpoint('POST', '/submit'),
        removeDraftClaim: endpoint('DELETE', '/:id'),
        getImageByBlobName: endpoint('GET', '/image/:blob'),
        addClaimReceipt: endpoint('POST', '/receipt'),
        getClaimReceiptByBlobName: endpoint('GET', '/receipt/:blob'),
    };
};
export default claimEndpoints;
