import { FocusEventHandler, forwardRef } from 'react';
import ReactSelect, {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  PlaceholderProps,
} from 'react-select';
import type { SimpleSelectOption } from '@reguard/common';
import { colors } from '@reguard/theme';

import ReactSelectIndicator from './ReactSelectIndicator';

interface SelectProps {
  options: SimpleSelectOption[];
  error?: string | boolean;
  errorExclamationIconVisible?: boolean;
  valid?: boolean;
  className?: string;
  label?: string;
  labelHtmlFor?: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  infoTextVisible?: boolean;
  onChange?: (
    newValue: SimpleSelectOption,
    actionMeta: ActionMeta<any>,
  ) => void;
  value?: any;
  disabled?: boolean;
  containerClassName?: string;
  infoTextClassName?: string;
  errorMessageClassName?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

const Placeholder = ({
  className,
  error,
  ...props
}: PlaceholderProps & { error?: boolean }) => {
  return (
    <components.Placeholder
      className={`${className} b2 ${
        error ? '!text-reguard-error' : '!text-reguard-inactive1'
      }`}
      {...props}
    />
  );
};

export const SimpleSelect = (
  {
    error,
    errorExclamationIconVisible,
    valid,
    label,
    className,
    infoText,
    infoTextVisible,
    value,
    disabled,
    containerClassName = '',
    placeholder,
    infoTextClassName = '',
    errorMessageClassName = '',
    ...props
  }: SelectProps,
  ref: any,
) => {
  const getBorderColor = (defaultState = 'reguard-slate') =>
    (error && 'reguard-error') ||
    (value && 'reguard-wintergreen') ||
    defaultState;

  return (
    <div className={containerClassName}>
      <label
        className={`tw-cst-pf font-normal flex justify-center select-none flex-col-reverse w-full`}
        htmlFor={props.name}
      >
        {error && (
          <span
            className={`c2 semibold text-reguard-error ${errorMessageClassName}`}
          >
            {error} <br />
          </span>
        )}
        <ReactSelect
          onFocus={props.onFocus}
          isDisabled={disabled}
          placeholder={placeholder}
          components={{
            DropdownIndicator: (passedProps: DropdownIndicatorProps) => (
              <ReactSelectIndicator
                {...passedProps}
                errorExclamationIconVisible={errorExclamationIconVisible}
                error={!!error}
                valid={valid}
              />
            ),
            Placeholder: (props: PlaceholderProps) => (
              <Placeholder {...props} error={!!error} />
            ),
          }}
          className={`h-12 text-reguard-indigo border-2 mb-1 mt-2 border-reguard-slate rounded w-full flex-auto
        hover:bg-reguard-pearl-tint disabled:bg-reguard-inactive1 disabled:text-reguard-inactive2

        ${!error ? 'placeholder:border-reguard-indigo' : ''}

        hover:border-reguard-violet focus:border-reguard-violet
        focus:outline-reguard-violet focus-visible:outline-reguard-violet ${className}`}
          classNamePrefix="react-select"
          styles={{
            container: provided => ({
              ...provided,
              'padding': 0,
              'borderColor': colors[getBorderColor('reguard-slate')],
              ':focus-within': {
                borderColor: colors[getBorderColor('reguard-violet')],
              },
            }),
            control: (provided, state) => ({
              ...provided,
              'border': 'none',
              'height': '100%',
              'outline': 'none',
              'boxShadow': 'none',
              'backgroundColor': state.getValue()[0]?.value
                ? colors['reguard-pearl-tint']
                : 'transparent',
              ':hover': {
                backgroundColor: colors['reguard-pearl-tint'],
              },
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          value={value}
          ref={ref}
          {...props}
        />

        <span className={`b2 ${error ? 'text-reguard-error' : ''}`}>
          {label}
        </span>
      </label>
      {infoTextVisible && (
        <div
          className={`text-sm text-reguard-violet semibold mt-2 lg:absolute ${
            error ? 'md:top-[6rem]' : ''
          } ${infoTextClassName}`}
        >
          {infoText}
        </div>
      )}
    </div>
  );
};

export const SimpleSelectForwardRef = forwardRef(SimpleSelect);
