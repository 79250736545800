import { ReportKeys } from '../../constants/report';

export const protectionPlanColumns = [
  { headerKey: 'Protection plan', dataKey: 'protection_plan' },
  { headerKey: 'Plan ID', dataKey: 'protection_plan_id' },
  {
    headerKey: '# of plans sold',
    dataKey: 'quantity',
  },
  { headerKey: 'Wholesale value', dataKey: 'wholesale_value' },
  { headerKey: '% of plans sold', dataKey: 'weight' },
];
export const salesByStoreColumns = [
  { headerKey: 'Store ID', dataKey: 'location' },
  { headerKey: 'Location', dataKey: 'salesLocation' },
  { headerKey: 'Reguard Sales Quantity', dataKey: 'quantity', canSort: true },
  {
    headerKey: 'Reguard Sales Value',
    dataKey: 'wholesale_value',
    canSort: true,
  },
];
export const tabs = [
  {
    title: 'Overall Sales',
    value: ReportKeys.ProtectionPlan,
    columns: protectionPlanColumns,
  },
  {
    title: 'Sales By Store',
    value: ReportKeys.SalesByStore,
    columns: salesByStoreColumns,
  },
];

export const performanceOutlierColumns = [
  { headerKey: '', dataKey: 'label' },
  { headerKey: 'Location', dataKey: 'salesLocation' },
  {
    headerKey: 'Figures',
    dataKey: '',
    getDataKey: (identifier: string) => {
      return identifier.includes('quantity') ? 'quantity' : 'wholesale_value';
    },
    conditionKey: 'identifier',
  },
  { headerKey: '% Total Sales', dataKey: 'sales_weight' },
];
