import {
  ChangeEvent,
  ClipboardEvent,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import classes from './TextField.module.css';

const ERROR_MESSAGE = {
  INSUFFICIENT_DIGITS: 'token_must_have_6_digits',
  ONLY_NUMBERS: 'token_must_have_only_numbers',
};

interface NumericInputProps {
  value: string;
  setValue: (value: string) => void;
  hasError: boolean;
  name: string;
}

interface TokenFormProps {
  namePrefix?: string;
  error: string;
  setError?: (message: string) => void;
  handleSubmit: (value: string) => Promise<void>;
  children?: ReactNode;
}

const onlyNumbers = (value: string) => /^[0-9]*$/g.test(value);

const NumericInput = ({
  value,
  setValue,
  hasError,
  name,
}: NumericInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '') {
      setValue('');
      return;
    }

    const numberValue = Number(value);
    const isNumber = typeof numberValue === 'number';

    if (isNumber) {
      const hasOneDigit = isNumber && numberValue < 10;

      if (hasOneDigit) {
        setValue(value);
      } else {
        //setValue(value.split('')[value.length - 1]);
      }

      const form = e.target.form;

      if (form) {
        const index = [...form].indexOf(e.target);
        const input = form.elements[index + 1] as HTMLInputElement;

        if (index <= 4) {
          input.focus();
        }
      }
    }
  };

  return (
    <div className={`h-12 w-10 ${classes.numericInput}`}>
      <input
        name={name}
        value={value || ''}
        onChange={handleChange}
        type="number"
        className={`tw-cst-pf h-12 ${
          hasError
            ? 'placeholder:text-reguard-error border-reguard-error'
            : 'placeholder:border-reguard-indigo border-reguard-slate'
        }
        text-reguard-indigo border-2 focus-visible:border-reguard-wintergreen rounded w-full flex-auto
        hover:bg-reguard-pearl-shadedisabled:bg-reguard-inactive1 disabled:text-reguard-inactive2 focus:outline-none
        p-0 text-center`}
      />
    </div>
  );
};

export const TokenForm: FC<TokenFormProps> = ({
  children,
  error,
  namePrefix = 'token-input',
  setError,
  handleSubmit,
}) => {
  const [segments, setSegments] = useState(['', '', '', '']);
  const [isSubmitting, setSubmit] = useState(false);
  const form = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (segments.join('').length === 6 && !error && !isSubmitting) {
      form.current &&
        form.current.dispatchEvent(
          new Event('submit', {
            bubbles: true, // Whether the event will bubble up through the DOM or not
            cancelable: true, // Whether the event may be canceled or not
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  const handleChangeInputByIndex = (index: number) => {
    return (value: string) => {
      if (error) {
        setError && setError('');
      }

      setSegments(values => {
        const newValues = [...values];
        newValues[index] = value;
        return newValues;
      });
    };
  };

  const handlePaste = (e: ClipboardEvent<HTMLFormElement>) => {
    const pasted = e.clipboardData.getData('text/plain');

    if (!onlyNumbers(pasted)) {
      setError && setError(t(ERROR_MESSAGE.ONLY_NUMBERS));
      return;
    }

    if (pasted.length < 6) {
      setError && setError(t(ERROR_MESSAGE.INSUFFICIENT_DIGITS));
      return;
    }

    if (error) {
      setError && setError('');
    }

    setSegments(pasted.split('').slice(0, 6));
  };

  return (
    <form
      id="profiletokenform"
      ref={form}
      onPaste={handlePaste}
      onSubmit={async e => {
        e.preventDefault();
        const token = segments.join('');

        if (token.length < 6) {
          setError && setError(t('token_not_valid'));
          return;
        }
        await handleSubmit(token);
        setSubmit(false);
      }}
    >
      <div className="flex flex-col items-center">
        <div
          className={`flex gap-3 justify-center tokenContainer mb-4 ${
            error ? 'animate-shake' : ''
          }`}
        >
          <NumericInput
            name={`${namePrefix}-${0}`}
            value={segments[0]}
            setValue={handleChangeInputByIndex(0)}
            hasError={error !== ''}
          />
          <NumericInput
            name={`${namePrefix}-${1}`}
            value={segments[1]}
            setValue={handleChangeInputByIndex(1)}
            hasError={error !== ''}
          />
          <NumericInput
            name={`${namePrefix}-${2}`}
            value={segments[2]}
            setValue={handleChangeInputByIndex(2)}
            hasError={error !== ''}
          />
          <NumericInput
            name={`${namePrefix}-${3}`}
            value={segments[3]}
            setValue={handleChangeInputByIndex(3)}
            hasError={error !== ''}
          />
          <NumericInput
            name={`${namePrefix}-${4}`}
            value={segments[4]}
            setValue={handleChangeInputByIndex(4)}
            hasError={error !== ''}
          />
          <NumericInput
            name={`${namePrefix}-${5}`}
            value={segments[5]}
            setValue={handleChangeInputByIndex(5)}
            hasError={error !== ''}
          />
        </div>
        {children}
      </div>
    </form>
  );
};
