import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import { useNavigate, useParams } from 'react-router-dom';
import { toI18nKey } from '@reguard/common';

import { ReactComponent as ActionOkSVG } from './assets/actionOk.svg';
import { buttonSelectors } from './tests/dataCySelectors';
import { SetPassFields } from './SetPassFields';
import { Button } from '.';

interface SetPassProps {
  createUser: (arg: any) => Promise<any>;
  setUserPassword: (arg: any) => Promise<any>;
  isMerchant?: boolean;
}

interface SetPassFormProps {
  createUser: (arg: any) => Promise<any>;
  setUserPassword: (arg: any) => Promise<any>;
  onSuccess: () => void;
  isMerchant?: boolean;
}

export const SetPass = ({
  createUser,
  setUserPassword,
  isMerchant,
}: SetPassProps) => {
  const navigate = useNavigate();
  const [saved, setSaved] = useState<boolean>(false);
  const onSuccess = () => {
    setSaved((prevState: any) => !prevState);
  };

  const onSuccessClick = (e: any) => {
    e.preventDefault();
    navigate('/', { replace: true });
  };

  return (
    <>
      {/* TODO: maybe move the modal a bit higher on the merchant portal */}
      <div
        className={`grid m-auto max-w-[18.25rem] ${
          isMerchant ? 'pb-0' : 'pb-4'
        }`}
      >
        {saved ? (
          <PasswordOk onClick={onSuccessClick} />
        ) : (
          <SetPassForm
            onSuccess={onSuccess}
            createUser={createUser}
            setUserPassword={setUserPassword}
            isMerchant={isMerchant}
          />
        )}
      </div>
    </>
  );
};

function SetPassForm({
  onSuccess,
  createUser,
  setUserPassword,
  isMerchant,
}: SetPassFormProps): JSX.Element {
  const params = useParams();

  if (!params.token) {
    throw new Error();
  }

  const token = atob(params.token);
  const decodedToken: { email: string; reset?: boolean } | null =
    decodeToken(token);

  if (!decodedToken) {
    throw new Error();
  }

  const showTempPass = isMerchant && !decodedToken?.reset;
  const onSubmit = async (
    { password, temporaryPassword }: any,
    { setSubmitting, setStatus }: any,
  ) => {
    let email: string | null = null;
    let reset: boolean | undefined;

    if (token && decodedToken) {
      email = decodedToken.email;
      reset = decodedToken.reset;
    }

    try {
      setStatus(undefined);
      setSubmitting(true);

      if (reset || isMerchant) {
        await setUserPassword({ password, token, email, temporaryPassword });
      } else {
        await createUser({ email, password, token });
      }

      onSuccess();
    } catch (e) {
      setStatus((e as { message: string }).message);
    } finally {
      setSubmitting(false);
    }
  };

  return <SetPassFields onSubmit={onSubmit} showTempPassword={showTempPass} />;
}

export function PasswordOk({
  onClick,
  label = 'Log in',
  chatbot,
}: {
  onClick: (e: any) => void;
  label?: string;
  chatbot?: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <form onSubmit={onClick}>
      <h4
        className={`tw-cst-pf ${
          chatbot ? 'mt-14' : 'md:mt-[7.625rem]'
        } mb-[2.125rem]`}
      >
        {t('password_set')}
      </h4>
      <div className="flex flex-col">
        <span className="b2 mb-8">
          {t('your_password_has_been_successfully_set')}
        </span>
        <ActionOkSVG className={`w-40 h-40 mt-14 mx-auto mb-16`} />
      </div>
      <Button
        className="w-[18rem] mb-[9.5rem]"
        data-cy={buttonSelectors.loginButton}
      >
        {t(toI18nKey(label))}
      </Button>
    </form>
  );
}
