import { initReactI18next } from 'react-i18next';
import { LanguageCodes } from '@reguard/common';
import i18n, { i18n as i18nInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
export function i18nInit(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseURL: string = import.meta.env.VITE_API_URL,
): Promise<i18nInstance> {
  return i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: LanguageCodes.EN,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: `${baseURL}/locales/translation/{{lng}}`,
      },
      supportedLngs: [LanguageCodes.EN, LanguageCodes.ES],
    })
    .then(() => i18n);
}
