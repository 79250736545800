import { getDefaultLanguage } from '@reguard/common';
import endpoints from '@reguard/endpoints';
import { LanguageType } from '@reguard/ui-components';
import _ from 'lodash';

import config from '../../config';

const apiURL = config.api.url;
const { getAuthenticatedUser } = endpoints().authEndpoints();

interface ClientProperties {
  data?: Record<string, unknown>;
  method?: string;
  token?: string;
  headers?: Record<string, unknown>;
  body?: string;
  customConfig?: Record<string, unknown>;
}

export enum httpCodes {
  'Unauthorized' = 401,
}

export type Client = (
  endpoint: string,
  config: ClientProperties,
) => Promise<any>;

async function client(
  endpoint: string,
  {
    data,
    method,
    token,
    headers: customHeaders,
    customConfig,
  }: ClientProperties,
) {
  const language = getDefaultLanguage(
    localStorage.getItem('language') ||
      localStorage.getItem('i18nextLng') ||
      LanguageType.EN,
  );
  const config = {
    method,
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include',
    headers: _.omitBy(
      {
        'Content-Type': data ? 'application/json' : undefined,
        'Authorization': token ? `Bearer ${token}` : undefined,
        'x-selected-language': language,
        ...customHeaders,
      },
      _.isNil,
    ),
    ...customConfig,
  } as RequestInit;

  return window.fetch(`${apiURL}${endpoint}`, config).then(async response => {
    const endPointData = await response.json();

    if (response.ok || endpoint === getAuthenticatedUser.path) {
      return endPointData;
    }

    if (response.status === httpCodes.Unauthorized) {
      window.location.href = '/';
      return;
    }

    if (response.ok || response.status === httpCodes.Unauthorized) {
      return endPointData;
    } else {
      return Promise.reject(endPointData);
    }
  });
}

function useClient() {
  return (endpoint: string, config: ClientProperties) =>
    client(endpoint, config);
}

export { client, useClient };
