import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerClaimStatus, toI18nKey } from '@reguard/common';

import { ReactComponent as CheckMark } from './assets/Checkmark.svg';

interface ProgressBarStatusProps {
  status: CustomerClaimStatus;
}

const statusList = [
  CustomerClaimStatus.UnderReview,
  CustomerClaimStatus.InProgress,
  CustomerClaimStatus.Closed,
];

export const ProgressBarStatus: FC<ProgressBarStatusProps> = (
  props: ProgressBarStatusProps,
) => {
  const { status } = props;
  const isClosed = status === CustomerClaimStatus.Closed;
  const isStatusActive = (cs: CustomerClaimStatus) =>
    cs === status ||
    (cs === CustomerClaimStatus.InProgress &&
      status === CustomerClaimStatus.Approved);
  const isStatusCompleted = (cs: CustomerClaimStatus) =>
    statusList.indexOf(cs) < statusList.indexOf(status) ||
    ([CustomerClaimStatus.InProgress, CustomerClaimStatus.UnderReview].includes(
      cs,
    ) &&
      status === CustomerClaimStatus.Approved);
  const { t } = useTranslation();
  const textColor = (cs: CustomerClaimStatus) => {
    if (isClosed) {
      return 'text-reguard-inactive1';
    }
    if (isStatusActive(cs)) {
      return 'text-reguard-violet';
    }
    if (isStatusCompleted(cs)) {
      return 'text-reguard-inactive1 md:text-reguard-violet';
    }
    return 'text-reguard-inactive1';
  };

  return (
    <div className="w-full flex">
      {statusList.map(s => (
        <div
          className="w-1/3 p-2 flex gap-2 justify-center items-center"
          key={s}
        >
          {isStatusActive(s) || isStatusCompleted(s) ? (
            <ActiveIcon isClosed={isClosed} />
          ) : (
            <InactiveIcon />
          )}
          <span className={`text-sm ${textColor(s)}`}>{t(toI18nKey(s))}</span>
        </div>
      ))}
    </div>
  );
};

interface ActiveIconProps {
  isClosed: boolean;
}
const ActiveIcon: FC<ActiveIconProps> = (props: ActiveIconProps) => (
  <div
    className={`hidden md:flex h-3 w-3 rounded-full justify-center items-center ${
      props.isClosed ? 'bg-reguard-inactive1' : 'bg-reguard-violet'
    }`}
  >
    <CheckMark />
  </div>
);

const InactiveIcon = () => (
  <div className="hidden md:flex h-3 w-3 rounded-full ring-1 ring-reguard-inactive1"></div>
);
