import testEndpoints from './src/test.endpoints.js';
import botEndpoints from './src/bot.endpoints.js';
import authEndpoints from './src/auth.endpoints.js';
import localesEndpoints from './src/locales.endpoints.js';
import claimEndpoints from './src/claim.endpoints.js';
import contractEndpoints from './src/contract.endpoints.js';
import domainEndpoints from './src/domain.endpoints.js';
import healthEndpoints from './src/health.endpoints.js';
import plansEndpoints from './src/plans.endpoints.js';
import purchaseEndpoints from './src/purchase.endpoints.js';
import userEndpoints from './src/user.endpoints.js';
import webhookEndpoints from './src/webhook.endpoints.js';
import trainingEndpoints from './src/training.endpoints.js';
import merchantEndpoints from './src/merchant.endpoints.js';
import captureEndpoints from './src/capture.endpoints.js';
import reportEndpoints from './src/report.endpoints.js';
import orderEndpoints from './src/order.endpoints.js';
import productEndpoints from './src/product.endpoints.js';
import accountsEndpoints from './src/accounts.endpoints.js';
import contractAnomaliesEndpoints from './src/contractAnomalies.js';
const initEndpoints = () => ({
    authEndpoints,
    localesEndpoints,
    claimEndpoints,
    contractEndpoints,
    healthEndpoints,
    plansEndpoints,
    purchaseEndpoints,
    webhookEndpoints,
    reportEndpoints,
    userEndpoints,
    domainEndpoints,
    botEndpoints,
    trainingEndpoints,
    merchantEndpoints,
    captureEndpoints,
    testEndpoints,
    orderEndpoints,
    productEndpoints,
    accountsEndpoints,
    contractAnomaliesEndpoints,
});
export const endpointConstructor = (baseUrl) => {
    return {
        endpoint: (method, urn = '', permissions = []) => ({
            path: baseUrl + urn,
            method,
            permissions,
        }),
    };
};
export default initEndpoints;
