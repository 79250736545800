import { useEffect } from 'react';
import CookieBot from 'react-cookiebot';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ErrorPage } from '@reguard/ui-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import config from './config';

import '@reguard/ui-components/Theme.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if ((error as any).status === 404 || (error as any).status === 401)
          return false;
        else if (failureCount < 2) return true;
        else return false;
      },
    },
    mutations: {},
  },
});

const { dsn, tracesSampleRate, environment } = config.sentry;

try {
  Sentry.init({
    environment: environment,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    // TODO: adjust the tracesSampleRate in production
    tracesSampleRate: tracesSampleRate,
  });
} catch (error: any) {
  console.error('Sentry config error in the merchant portal: ', error);
}

// TODO: improve to use shared error codes and messages
const handleErrorFallback = ({
  error,
  resetError,
}: {
  error: { message: string };
  resetError: () => void;
}) => (
  <ErrorPage
    message={error.message}
    status={404}
    callToAction={resetError}
    ctaLabel="Try to reset"
  />
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Sentry.ErrorBoundary showDialog fallback={handleErrorFallback}>
        <CookieBot domainGroupId={config.cookieBot.domainId} />
        <App />
      </Sentry.ErrorBoundary>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);
