/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProtectionPlan,
  ProtectionPlanDetailsTable,
  ProtectionPlanDetailsTabs,
} from '.';

interface CoverageHighlightsProps {
  protectionPlan: ProtectionPlan;
}
export const ProtectionPlanCoverageHighlights: FC<CoverageHighlightsProps> = (
  props: CoverageHighlightsProps,
) => {
  const { protectionPlan } = props;
  const covered = protectionPlan.coveredDamages.filter(cd => cd.isCovered);
  const [firstDamage] = covered;
  const coverageTableLabels = Object.keys(firstDamage?.tableConfig);
  const { t } = useTranslation();
  return (
    <>
      <div className="visible md:hidden">
        <ProtectionPlanDetailsTabs
          tables={coverageTableLabels.map((cl, index) => (
            <ProtectionPlanDetailsTable
              key={index}
              coveredDamages={covered}
              mainHeading={t('damage')}
              columns={[cl]}
            />
          ))}
          tabNames={coverageTableLabels}
        />
      </div>

      <div className="hidden md:block w-full">
        <ProtectionPlanDetailsTable
          coveredDamages={covered}
          mainHeading={t('damage')}
          columns={coverageTableLabels}
        />
      </div>
    </>
  );
};
