import { endpointConstructor } from '../index.js';
const authEndpoints = () => {
    const { endpoint } = endpointConstructor('/auth');
    return {
        sendAuthEmail: endpoint('POST', '/email'),
        loginLocal: endpoint('POST', '/login'),
        loginPasswordless: endpoint('POST', '/login/passwordless'),
        requestEmailVerificationCode: endpoint('POST', '/otp/email'),
        requestSmsVerificationCode: endpoint('POST', '/otp/sms'),
        getAuthenticatedUser: endpoint('GET', '/user'),
        loginMerchant: endpoint('POST', '/login-merchant'),
        logout: endpoint('POST', '/logout'),
        checkUserBySalesId: endpoint('POST', '/verify-by-sale'),
        loginWithContract: endpoint('POST', '/login/contract'),
        loginWithOtp: endpoint('POST', '/login/otp'),
    };
};
export default authEndpoints;
