import { t } from 'i18next';

interface NoSalesTableProps {
  totalStoresWithoutSales: number;
}

const NoSalesTable = ({ totalStoresWithoutSales }: NoSalesTableProps) => {
  return totalStoresWithoutSales && totalStoresWithoutSales > 0 ? (
    <table className="permissions-table w-full mt-8">
      <thead>
        <tr className=" permissions-table_header overflow-hidden semibold text-lg mb-4"></tr>
      </thead>

      <tbody>
        <tr
          className={`permissions-table_row items-center overflow-hidden mb-3 md:mb-0 bg-red-200`}
        >
          <td>{t('total_stores_without_sales')}</td>
          <td className="permissions-table_column">
            <div className="break-normal p-2">{totalStoresWithoutSales}</div>
          </td>
        </tr>
      </tbody>
    </table>
  ) : null;
};

export default NoSalesTable;
