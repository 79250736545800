import { useMutation, useQuery, useQueryClient } from 'react-query';
import endpoints from '@reguard/endpoints';
import type { User } from '@reguard/ui-components';

import { useClient } from './client';

const {
  create,
  changeEmail,
  setPasswordMerchant,
  findAll,
  verifyEmailMerchant,
  requestVerifyEmailMerchant,
} = endpoints().userEndpoints();

// todo separate auth api from user api
const { loginLocal, logout, sendAuthEmail, getAuthenticatedUser } =
  endpoints().authEndpoints();

const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options,
});

// todo either updateUserEmail or changeUserEmail
function useUpdateUserEmail(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data => client(changeEmail.path, { method: changeEmail.method, data }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useCreateUser(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(create.path, { method: create.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useSetMerchantPassword(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(setPasswordMerchant.path, {
        method: setPasswordMerchant.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useLogin(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    params =>
      client(loginLocal.path, {
        method: loginLocal.method,
        data: params,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useLogout(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(() => client(logout.path, { method: logout.method }), {
    ...defaultMutationOptions(options),
    onSuccess: () => {
      queryClient.resetQueries('myUser');
      queryClient.resetQueries('myTrainingUser');
    },
  });
}

function useInviteUser(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(sendAuthEmail.path, { method: sendAuthEmail.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useMyUser(options = {}) {
  const client = useClient();
  const result = useQuery<User, Error>({
    queryKey: 'myUser',
    queryFn: () => client(getAuthenticatedUser.path, {}).then(data => data),
    retry: false,
    ...options,
  });

  return { ...result, data: (result.data as User) ?? {} };
}

function useAllUsers(options: {
  page: number;
  perPage: number;
  searchText?: string;
}) {
  const client = useClient();

  const url = `${findAll.path}?&page=${options.page}&perPage=${
    options.perPage
  }${options.searchText ? `&searchText=${options.searchText}` : ``}`;

  const result = useQuery<User[], Error>(['allUsers', { ...options }], () =>
    client(url, {}),
  );

  return { ...result, data: result.data ?? {} };
}

function useVerifyEmail(options = {}) {
  const client = useClient();

  return useMutation(
    data =>
      client(verifyEmailMerchant.path, {
        method: verifyEmailMerchant.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useRequestVerifyEmail(options = {}) {
  const client = useClient();

  return useMutation(
    data =>
      client(requestVerifyEmailMerchant.path, {
        method: requestVerifyEmailMerchant.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

export {
  useCreateUser,
  useInviteUser,
  useLogin,
  useLogout,
  useMyUser,
  useSetMerchantPassword,
  useUpdateUserEmail,
  useAllUsers,
  useVerifyEmail,
  useRequestVerifyEmail,
};
