import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@reguard/ui-components';

interface LogoWithTitleProps {
  className?: string;
}

const LogoWithTitle: FC<LogoWithTitleProps> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center ${className}`}>
      <Logo />
      <span className="b1 text-reguard-pearl ml-[0.375rem]">
        {t('for_merchants')}
      </span>
    </div>
  );
};

export default LogoWithTitle;
