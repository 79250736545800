/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TableCrossBlackSVG } from './assets/tableCrossBlack.svg';
import { ReactComponent as TableTickGreenSVG } from './assets/tableTickGreen.svg';
import type { ProtectionPlanCoveredDamage } from '.';

interface TableProps {
  coveredDamages: ProtectionPlanCoveredDamage[];
  mainHeading: string;
  columns: string[];
}
export const ProtectionPlanDetailsTable: FC<TableProps> = (
  props: TableProps,
) => {
  const { coveredDamages, mainHeading, columns } = props;
  const { t } = useTranslation();
  const getIcon = (flag: boolean | null) => {
    if (flag === null) {
      return '';
    }
    return flag ? <TableTickGreenSVG /> : <TableCrossBlackSVG />;
  };

  return (
    <div className="pt-0 plan-details-table sm:pt-8 py-0 md:py-0 flex flex-col w-full">
      <div
        className={`plan-details-header-row 
        flex
        flex-row py-2 text-sm semibold justify-between`}
      >
        <div className="plan-details-left-side flex-row w-6/12 md:w-8/12 justify-start items-center">
          <div className="plan-details-col semibold">{mainHeading}</div>
        </div>

        <div
          className={`plan-details-right-side flex flex-row w-6/12 md:w-4/12 max-w-xxs ${
            columns.length > 1 ? 'justify-around' : 'justify-end'
          } items-center`}
        >
          {columns.map(
            ch =>
              ch && (
                <div key={ch} className="plan-details-col px-0.5">
                  {t(ch)}
                </div>
              ),
          )}
        </div>
      </div>

      {coveredDamages.map((item: ProtectionPlanCoveredDamage) => (
        <div
          className="plan-details-row flex flex-row py-2 pl-2 justify-between even:bg-reguard-pearl text-sm"
          key={item.id}
        >
          <div className="plan-details-left-side flex flex-row w-6/12 md:w-8/12 justify-start items-center">
            <div
              dangerouslySetInnerHTML={{
                __html: t(item.coveredDamage).replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br />',
                ),
              }}
            ></div>
          </div>

          {
            <div
              className={`plan-details-right-side flex flex-row w-6/12 md:w-4/12 max-w-xxs ${
                columns.length > 1 ? 'justify-around' : 'justify-end pr-6'
              } items-center`}
            >
              {columns.map(column => (
                <div className="plan-details-col" key={`${column}-${item.id}`}>
                  {getIcon(item.tableConfig[column])}
                </div>
              ))}
            </div>
          }
        </div>
      ))}
    </div>
  );
};
