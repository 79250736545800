import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProtectionPlan } from '@reguard/ui-components';
import type { User, UserRoleType } from '@reguard/ui-components/models/User';

import { ReactComponent as UserSVG } from '../assets/user.svg';
import LogoWithTitle from './LogoWithTitle';
import Menu from './Menu';

interface SidebarProps {
  onClick: () => void;
  userRole: UserRoleType;
  user?: User;
  plans: {
    _metadata: any;
    records: ProtectionPlan[];
  };
}

export const Sidebar: FC<SidebarProps> = ({
  user,
  onClick,
  userRole,
  plans,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ gridArea: 'sidebar' }}
      className={`hidden bg-reguard-indigo sm:flex flex-col justify-between w-full pt-8 pb-12 pl-5 pr-5 overflow-auto`}
    >
      <div>
        <LogoWithTitle className="mb-12" />

        <div className="w-full">
          <Menu userRole={userRole} plans={plans} />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex w-auto">
          <UserSVG className="mr-3" />
          <div className="b2 text-reguard-fog">
            {user?.name}
            <div className="b2b">{user?.role}</div>
          </div>
        </div>

        <button
          className="c2 text-reguard-pearl whitespace-nowrap"
          onClick={onClick}
        >
          {t('log_out')}
        </button>
      </div>
    </div>
  );
};
