import { FC, useEffect, useRef } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { colors } from '@reguard/theme';

interface LoaderGlobalProps {
  isLoading: boolean;
  onLoaderFinished?: () => void;
}

export const LoaderGlobal: FC<LoaderGlobalProps> = ({
  isLoading,
  onLoaderFinished,
}) => {
  const loaderRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (loaderRef.current) {
      if (isLoading) {
        loaderRef.current.continuousStart(20, 2);
      } else {
        loaderRef.current.complete();
      }
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loaderRef.current && loaderRef.current.complete();
    };
  }, [isLoading]);

  return (
    <LoadingBar
      loaderSpeed={1000}
      onLoaderFinished={onLoaderFinished}
      color={colors['reguard-wintergreen']}
      ref={loaderRef}
    />
  );
};
