import { t } from 'i18next';

interface ProtectPlanBoxProps {
  totalSales: {
    units_sold: string;
    wholesale_value: number;
  };
}

export const ProtectPlanBox = ({ totalSales }: ProtectPlanBoxProps) => {
  return (
    <fieldset className="flex justify-end">
      <div className="grid text-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <label className="group relative flex items-center justify-center rounded-md w-full sm:w-60 border py-3 px-4 text-sm font-medium hover:bg-gray-50 focus:outline-none sm:py-6 bg-white text-gray-900 shadow-sm">
          <div className="mx-auto flex max-w-xs flex-col gap-y-2">
            <dt className="text-base leading-5 text-gray-600 uppercase"> </dt>
            <dd className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {totalSales.units_sold}
            </dd>{' '}
            <dt className="text-base leading-5 text-gray-600 uppercase">
              {t('of_plans_sold')}
            </dt>
          </div>
        </label>
        <label className="group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium hover:bg-gray-50 focus:outline-none sm:py-6 bg-white text-gray-900 shadow-sm">
          <div className="mx-auto flex max-w-xs flex-col gap-y-2">
            <dt className="text-base leading-5 text-gray-600 uppercase">
              reguard {t('holding')}
            </dt>
            <dd className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              ${totalSales.wholesale_value}
            </dd>
            <dt className="text-base leading-5 text-gray-600 uppercase">
              {t('gross_revenue')}
            </dt>
          </div>
        </label>
      </div>
    </fieldset>
  );
};
