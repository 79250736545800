import { useTranslation } from 'react-i18next';
import { ErrorPage, LoaderGlobal } from '@reguard/ui-components';

import { useMyTrainingUser } from '../network/api/training';

export const TrainingPortal = () => {
  const { t } = useTranslation();
  const { data: trainingUserData, isLoading } = useMyTrainingUser({
    enabled: false,
  });

  if (isLoading || trainingUserData?.message)
    return <LoaderGlobal isLoading={true} />;

  if (!trainingUserData?.login)
    return (
      <ErrorPage
        message={t('training_profile_not_found') as string}
        status={404}
      />
    );

  if (trainingUserData?.status !== 'active') {
    return (
      <ErrorPage
        message={
          t(
            'you_need_to_ask_an_admin_to_activate_your_training_account',
          ) as string
        }
        status={404}
      />
    );
  }

  // TODO: how should we handle multiple branches?
  const [accessUrl] = trainingUserData.accessUrls;

  return (
    <iframe
      title="training-portal"
      width="100%"
      height="100%"
      src={accessUrl}
    />
  );
};
