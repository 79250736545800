import { useMutation, useQueryClient } from 'react-query';
import endpoints from '@reguard/endpoints';

import { useClient } from './client';

const { getAllReports, filterReportsByMerchant } =
  endpoints().reportEndpoints();

const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options,
});

function useGetAllReports(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(getAllReports.path, { method: getAllReports.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useFilterReportsByMerchant() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(filterReportsByMerchant.path, {
        method: filterReportsByMerchant.method,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('filterReportsByMerchant'),
    },
  );
}

export { useGetAllReports, useFilterReportsByMerchant };
