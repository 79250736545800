import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '@reguard/ui-components';

import { canRouteBack } from '../../constants/back-enabled-routes';
import BackButton from '../assets/BackButton.svg';
import HelpButton from '../assets/HelpButton.svg';

export function Header(props: { url?: string }) {
  const location = useLocation();
  const { pathname: routeName } = location;
  const canBack = canRouteBack(routeName);
  const { t } = useTranslation();
  return (
    <div className="lg:max-w-[37.5rem] lg:mx-auto lg:pt-[8.5rem] ">
      <header className="flex justify-between items-center p-4 bg-reguard-indigo rounded-t-lg lg:max-h-[3.6rem] ">
        <div className="min-w-[72px] min-h-1">
          {canBack && <BackBtn url={props.url} />}
        </div>
        <div className="flex items-center">
          <Logo />
          <span className="b2b text-reguard-pearl">{t('for_merchants')}</span>
        </div>
        <a
          className="tw-cst-pf"
          href={'https://www.reguardprotection.com/how-it-works#FAQ'}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Help"
            src={HelpButton as any}
            className="HelpButton tw-cst-pf"
          />
        </a>
      </header>
    </div>
  );
}

function BackBtn(props: { url?: string }) {
  const { t } = useTranslation();
  const url = props.url || '/';
  return (
    <Link to={url} className="cursor-pointer">
      <img
        alt={t('back') as string}
        src={BackButton as unknown as string}
        className="BackButton tw-cst-pf"
      />
    </Link>
  );
}
