import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFormat, MainPage, notify } from '@reguard/ui-components';
import { t } from 'i18next';
import Papa from 'papaparse';

import DynamicTable from './shared/DynamicTable';
import Filter from './shared/Filter';
import NoSalesTable from './shared/NoSalesTable';
import { ProtectPlanBox } from './shared/ProtectPlanBox';
import ReportTable from './shared/ReportTable';
import {
  performanceOutlierColumns,
  protectionPlanColumns,
  salesByStoreColumns,
  tabs,
} from './util/financialReport';
import {
  SmallBoxSkeleton,
  TableSkeleton,
} from '../../../ui/components/Skeleton';
import { ReportKeys } from '../constants/report';
import {
  useFilterReportsByMerchant,
  useGetAllReports,
} from '../network/api/reports';

type columnsType = {
  headerKey: string;
  dataKey: string;
  canSort?: boolean;
}[];
type columnsDataType = {
  [key: string]: string;
};

export const FinancialReport = ({ merchants }: any) => {
  const { mutate: getAllReports, data: allReports } = useGetAllReports();

  const { mutateAsync: filterReports } = useFilterReportsByMerchant();

  const start = new Date('2023-05-1');
  const [date, setDate] = useState({
    startDate: start,
    endDate: new Date(),
  });
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [reportPayload, setReportPayload] = useState({
    category: 'financial',
    date: { start: dateFormat(date.startDate), end: dateFormat(date.endDate) },
    merchants,
  });

  const [merchant, setSelectedMerchant] = useState<string>('all merchants');
  const [, setAllReportData] = useState<any>({});
  const [performanceOutliers, setPerformanceOutliers] = useState({});
  const [protectionPlan, setProtectionPlan] = useState({});
  const [salesByStore, setSalesByStore] = useState({});
  const [totalSales, setTotalSales] = useState({
    units_sold: '',
    wholesale_value: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSortLoading, setIsSortLoading] = useState<boolean>(false);
  const [storesWithoutSales, setStoresWithoutSales] = useState([]);

  useEffect(() => {
    setReportPayload(prev => ({
      ...prev,
      date: {
        start: dateFormat(date.startDate),
        end: dateFormat(date.endDate),
      },
    }));
  }, [date]);

  useEffect(() => {
    setIsLoading(true);

    if (merchant === 'all merchants') {
      return setAllReportData(getAllReports({ ...reportPayload }));
    }

    handleReportingUpdate(merchant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPayload.date.start, reportPayload.date.end]);

  useEffect(() => {
    setIsLoading(true);
    if (allReports) {
      const { performanceOutliers, protectionPlans, salesByStore, totalSales } =
        allReports[0];

      setPerformanceOutliers(performanceOutliers);
      setProtectionPlan(protectionPlans);
      setSalesByStore(salesByStore);
      setTotalSales(totalSales);

      const noSales = salesByStore.filter(function (sale) {
        return sale.wholesale_value === 0;
      });

      setStoresWithoutSales(noSales);
      setIsLoading(false);
    }
  }, [allReports]);

  const handleReportingUpdate = async merchant => {
    try {
      setIsLoading(true);

      if (merchant !== 'all merchants') {
        const filters = { merchantId: merchant };
        const data = { filters, ...reportPayload };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const result = await filterReports(data);

        const {
          performanceOutliers,
          protectionPlans,
          salesByStore,
          totalSales,
        } = result[0];

        setPerformanceOutliers(performanceOutliers);
        setProtectionPlan(protectionPlans);
        setSalesByStore(salesByStore);
        setTotalSales(totalSales);

        const noSales = salesByStore.filter(function (sale) {
          return sale.wholesale_value === 0;
        });

        setStoresWithoutSales(noSales);
      } else {
        const all = getAllReports({ ...reportPayload });
        setAllReportData(all);
      }
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      notify({
        title: t('error_while_filtering_merchants'),
        options: {
          type: 'error',
        },
      });
    }
  };

  const handleFilterReports = async event => {
    const merchant = event.target.value;

    setSelectedMerchant(merchant);
    return handleReportingUpdate(merchant);
  };

  const downloadCSV = (data: string[], headers, filename) => {
    const csvData = [headers, ...data];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getTableData = (dataArray, columns: columnsType) =>
    dataArray.map((d: columnsDataType) => columns.map(item => d[item.dataKey]));
  const getHeaderData = headerArray => headerArray.map(item => item.headerKey);
  const handleDownload = () => {
    downloadCSV(
      getTableData(protectionPlan, protectionPlanColumns),
      getHeaderData(protectionPlanColumns),
      `${ReportKeys.ProtectionPlan}.csv`,
    );
    downloadCSV(
      getTableData(salesByStore, salesByStoreColumns),
      getHeaderData(salesByStoreColumns),
      `${ReportKeys.SalesByStore}.csv`,
    );
    downloadCSV(
      getTableData(performanceOutliers, performanceOutlierColumns),
      getHeaderData(performanceOutlierColumns),
      `${ReportKeys.PerformanceOutliers}.csv`,
    );
  };
  return (
    <MainPage className="h-screen">
      <div className="px-12 sm:px-16 pt-32 sm:pt-12 overflow-auto h-full">
        <div className="flex gap-2 text-3xl font-bold items-baseline">
          <span className="font-normal underline">{t('financial_report')}</span>
        </div>
        <div
          className="mt-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
          role="tablist"
        >
          {tabs.map(tab => (
            <div key={tab.value} className="flex gap-4 mr-4">
              <Link
                to="#"
                className={
                  selectedTab.title === tab.title
                    ? 'text-reguard-wintergreen-shade'
                    : 'text-reguard-indigo-tint hover:underline'
                }
                onClick={() => setSelectedTab(tab)}
              >
                {t(tab.title)}
              </Link>
            </div>
          ))}
        </div>
        <div className="pr-[7.5rem] sm:pr-16 flex flex-row-reverse gap-4">
          <button onClick={handleDownload} className="relative top-2.5">
            {t('download_as_csv')}
          </button>
          {merchants.length > 0 && (
            <div className="relative top-2">
              <select
                className="bg-blue-900 border border-[#4757D1]
            text-white text-sm rounded-lg focus:ring-[#4757D1]
            focus:border-blue-900 block w-full p-2.5 dark:bg-blue-900 dark:border-blue-900
             dark:focus:ring-[#4757D1] dark:focus:border-[#4757D1]"
                onChange={event => handleFilterReports(event)}
              >
                <option value="all merchants">All Merchants</option>
                {merchants.map(merchant => {
                  return (
                    <option key={merchant.id} value={merchant.id}>
                      {merchant.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <Filter setDate={setDate} />
        </div>
        <div className="p-[7.5rem] pt-[10.25rem] sm:p-16 sm:pt-12 overflow-auto">
          {selectedTab.title === 'Overall Sales' &&
            totalSales &&
            (isLoading ? (
              <SmallBoxSkeleton />
            ) : (
              <ProtectPlanBox totalSales={totalSales} />
            ))}
          {selectedTab.title === 'Sales By Store' &&
            (isLoading ? (
              <TableSkeleton />
            ) : (
              <>
                <DynamicTable
                  columns={performanceOutlierColumns}
                  data={performanceOutliers}
                  setIsSortLoading={setIsSortLoading}
                />
                <NoSalesTable
                  totalStoresWithoutSales={storesWithoutSales.length}
                />
              </>
            ))}
          {!isSortLoading && isLoading ? (
            <TableSkeleton />
          ) : (
            <ReportTable
              data={
                selectedTab.value === ReportKeys.ProtectionPlan
                  ? protectionPlan
                  : salesByStore
              }
              isLoading={isLoading}
              getReports={getAllReports}
              reportPayload={reportPayload}
              report={selectedTab}
              setIsSortLoading={setIsSortLoading}
              isSortLoading={isSortLoading}
            />
          )}
        </div>
      </div>
    </MainPage>
  );
};
