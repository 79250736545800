import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Login as LoginForm } from '@reguard/ui-components';

import { useLogin } from '../network/api/users';

export const Login = () => {
  const { mutateAsync: login, status } = useLogin();
  const [error, setError] = useState(false);
  const { search } = useLocation();
  const authError = search.includes('?authError=true');
  const isFetching = status === 'loading';
  const { t } = useTranslation();

  const handleSubmit = async (data: any) => {
    const { email, password, remember } = data;

    try {
      await login({
        email,
        password,
        remember,
      });
    } catch (err) {
      setError(true);
    }
  };

  return (
    <div className={`grid m-auto max-w-[16rem] pb-[12rem]`}>
      <LoginForm
        isMerchant
        handleSubmit={handleSubmit}
        hasError={error || authError}
        isFetching={isFetching}
        forgotPassword={
          <Link to="/forgot-password" className="c1 mt-3 mb-4 cursor-pointer">
            {t('i_forgot_my_password')}
          </Link>
        }
        clearApiError={() => error && setError(false)}
        apiErrorContent={
          <>
            {error && (
              <span className="error-text c1  mt-7 mb-1  text-reguard-error">
                {t('your_email_or_password_is_incorrect')} <br />
                {t('please_try_again')}
              </span>
            )}
            {authError && (
              <span className="error-text c1 mt-7 mb-1 text-reguard-error">
                {t(
                  'please_try_again_in_a_few_minutes_there_was_an_error_logging_you_in',
                )}
              </span>
            )}
          </>
        }
      />
    </div>
  );
};
