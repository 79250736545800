export var CustomerClaimStatus;
(function (CustomerClaimStatus) {
    CustomerClaimStatus["Draft"] = "Draft";
    CustomerClaimStatus["UnderReview"] = "Under Review";
    CustomerClaimStatus["Approved"] = "Approved";
    CustomerClaimStatus["InProgress"] = "In Progress";
    CustomerClaimStatus["Closed"] = "Closed";
})(CustomerClaimStatus || (CustomerClaimStatus = {}));
export var CustomerClaimSolution;
(function (CustomerClaimSolution) {
    CustomerClaimSolution["Part"] = "Part";
    CustomerClaimSolution["Repair"] = "Repair";
    CustomerClaimSolution["Reimbursement"] = "Reimbursement";
    CustomerClaimSolution["Replacement"] = "Replacement";
    CustomerClaimSolution["CleaningKit"] = "Cleaning kit";
    CustomerClaimSolution["Void"] = "Claim voided";
    CustomerClaimSolution["Denied"] = "Claim denied";
    CustomerClaimSolution["NoResponse"] = "No response";
    CustomerClaimSolution["Resolved"] = "Claim resolved";
})(CustomerClaimSolution || (CustomerClaimSolution = {}));
export var CustomerClaimProductCondition;
(function (CustomerClaimProductCondition) {
    CustomerClaimProductCondition["StructuralDamage"] = "SD";
    CustomerClaimProductCondition["SurfaceDamage"] = "SF";
    CustomerClaimProductCondition["CosmeticDamage"] = "CD";
    CustomerClaimProductCondition["Intermittent"] = "IT";
    CustomerClaimProductCondition["Accumulation"] = "AC";
    CustomerClaimProductCondition["PreviousModifications"] = "MD";
})(CustomerClaimProductCondition || (CustomerClaimProductCondition = {}));
export const ClaimProductConditionsValues = [
    {
        value: CustomerClaimProductCondition.StructuralDamage,
        label: 'Product has structural damage',
    },
    {
        value: CustomerClaimProductCondition.SurfaceDamage,
        label: 'Product has surface damage',
    },
    {
        value: CustomerClaimProductCondition.CosmeticDamage,
        label: 'Product has only cosmetic damage',
    },
    {
        value: CustomerClaimProductCondition.Intermittent,
        label: 'Product only works sometimes because of damage',
    },
    {
        value: CustomerClaimProductCondition.Accumulation,
        label: 'Product has damage from accumulation',
    },
    {
        value: CustomerClaimProductCondition.PreviousModifications,
        label: 'Product has damage because of previous modification',
    },
];
export var ClaimFileType;
(function (ClaimFileType) {
    ClaimFileType["Image"] = "image";
    ClaimFileType["Receipt"] = "receipt";
})(ClaimFileType || (ClaimFileType = {}));
export var ClaimKind;
(function (ClaimKind) {
    ClaimKind["FPP"] = "FPP";
    ClaimKind["SI"] = "SI";
})(ClaimKind || (ClaimKind = {}));
