/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, ReactElement, ReactNode, useState } from 'react';

interface TabProps {
  tables: ReactNode[] | ReactElement[];
  tabNames: string[];
}
export const ProtectionPlanDetailsTabs: FC<TabProps> = (props: TabProps) => {
  const { tables, tabNames } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index: any) => setActiveIndex(index);
  const checkActive = (index: any, className: any) =>
    activeIndex === index ? className : '';

  return (
    <div className="py-2 w-full">
      <div className="flex flex-row">
        {tabNames.map((tabName: string, index: number) => (
          <button
            key={index}
            className={`justify-center items-center	w-full border-none py-4
              px-0 semibold mx-0.5 first:rounded-l-2xl first:mx-0
              last:rounded-r-2xl last:mx-0 ${
                checkActive(index, 'active') === 'active'
                  ? 'bg-reguard-indigo text-white'
                  : 'bg-slate-300'
              }`}
            onClick={() => handleClick(index)}
          >
            {tabName}
          </button>
        ))}
      </div>
      <div className="panels">
        {tables.map((table: ReactNode | ReactElement, index: number) => (
          <div
            key={index}
            className={`${
              checkActive(index, 'active') === 'active' ? 'block ' : 'hidden'
            }`}
          >
            {table}
          </div>
        ))}
      </div>
    </div>
  );
};
