import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  CloseSVG,
  ProtectionPlan,
  useBlockScroll,
} from '@reguard/ui-components';
import type { User, UserRoleType } from '@reguard/ui-components/models/User';

import { ReactComponent as HambMenuSVG } from '../assets/hambMenu.svg';
import LogoWithTitle from './LogoWithTitle';
import Menu from './Menu';

interface MobileHeaderProps {
  user?: User;
  onLogout: () => void;
  plans: {
    _metadata: any;
    records: ProtectionPlan[];
  };
  userRole: UserRoleType;
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  userRole,
  user,
  onLogout,
  plans,
}) => {
  useBlockScroll();
  const location = useLocation();
  const [heightClass, setHeightClass] = useState('h-0');
  const { t } = useTranslation();
  const isMenuVisible = heightClass !== 'h-0';

  useEffect(() => {
    if (isMenuVisible) {
      setHeightClass('h-0');
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header className="block sm:hidden fixed top-0 left-0 right-0 overflow-hidden z-10 bg-reguard-indigo">
      <div className="flex sm:hidden justify-between px-6 h-10 items-center text-xs text-reguard-indigo-tint bg-white sm:w-full">
        <div className="c2 font-proximaNovaLt">
          {user?.name} <span className="c2b">{user?.role}</span>
        </div>

        <div className="flex">
          <Link
            to=""
            onClick={onLogout}
            className="text-reguard-indigo-tint font-proximaNovaLt"
          >
            {t('log_out')}
          </Link>
        </div>
      </div>
      <div className="flex justify-between items-center px-6 p-4">
        <LogoWithTitle />

        <button
          onClick={() =>
            setHeightClass(currVal => (currVal === 'h-0' ? 'h-screen' : 'h-0'))
          }
          className="text-2xl text-reguard-pearl font-extralight"
        >
          {isMenuVisible ? <CloseSVG /> : <HambMenuSVG />}
        </button>
      </div>
      <div
        className={`sm:hidden w-screen ${heightClass} bg-reguard-indigo slide-down`}
      >
        <div className="py-2 flex flex-col w-full items-center">
          <Menu userRole={userRole} plans={plans} />
        </div>
      </div>
    </header>
  );
};
