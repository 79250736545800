import type { FC, ReactNode } from 'react';

import { mainPageSelectors } from './tests/dataCySelectors';

interface MainPageProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

export const MainPage: FC<MainPageProps> = ({
  title,
  children,
  className = '',
}) => (
  <main
    className={`bg-reguard-pearl-tint flex flex-1 w-full flex-col main-page ${className}`}
    data-cy={mainPageSelectors.mainPage}
    data-testid="main-page"
  >
    {title && (
      <h2 className="tw-cst-pf text-2xl text-reguard-indigo pb-6 px-6 sm:px-16 md:hidden">
        {title}
      </h2>
    )}
    <>{children}</>
  </main>
);
