import { endpointConstructor } from '../index.js';
const accountsEndpoints = () => {
    const { endpoint } = endpointConstructor('/accounts');
    return {
        getAccounts: endpoint('GET', '/'),
        getAccount: endpoint('GET', '/:id'),
        searchAccounts: endpoint('POST', '/search'),
        updateAccount: endpoint('PUT', '/:id'),
    };
};
export default accountsEndpoints;
