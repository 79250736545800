import { useMutation, useQuery, useQueryClient } from 'react-query';
import endpoints from '@reguard/endpoints';
import type { Merchant, MerchantStore } from '@reguard/ui-components';

import { useClient } from './client';
import getParamsString from './getParamsString';

const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options,
});

const { getAllMerchants, setTrainingPortalEnabled, getStores } =
  endpoints().merchantEndpoints();

function useGetAllMerchants() {
  // TODO: this will need to change if the ui requires pagination
  const client = useClient();
  const url = `${getAllMerchants.path}?&page=${1}&perPage=${20}`;
  const result = useQuery({
    queryKey: ['allMerchants'],
    queryFn: () => client(url, {}).then((data: any) => data),
  });

  return { ...result, data: (result.data as { records: Merchant[] }) ?? {} };
}

function useGetStores({
  page = 1,
  perPage = 20,
  searchText = '',
}: {
  page?: number;
  perPage?: number;
  searchText: string;
}) {
  const client = useClient();
  const url = `${getStores.path}${getParamsString({
    page,
    perPage,
    searchText,
  })}`;

  const result = useQuery({
    queryKey: ['stores', { page, perPage, searchText }],
    queryFn: () => client(url, {}).then((data: any) => data),
  });

  return {
    ...result,
    data: (result.data as { records: MerchantStore[] }) ?? [],
  };
}

function useSetTrainingPortalEnabled(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(setTrainingPortalEnabled.path, {
        method: setTrainingPortalEnabled.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => {
        queryClient.invalidateQueries('allMerchants');
      },
      onMutate: async newData => {
        await queryClient.cancelQueries({ queryKey: ['allMerchants'] });
        const previousData = queryClient.getQueryData(['allMerchants']);

        queryClient.setQueryData(['allMerchants'], (old: any) => {
          const optmisticData = {
            ...old,
            records: old.records.map(oldrecord => {
              if (oldrecord.id === newData.merchantId) {
                return {
                  ...oldrecord,
                  trainingPortalEnabled: newData.trainingPortalEnabled,
                };
              }
              return {
                ...oldrecord,
              };
            }),
          };

          return optmisticData;
        });
        return { previousData };
      },
    },
  );
}

export { useGetAllMerchants, useSetTrainingPortalEnabled, useGetStores };
