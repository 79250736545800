import { endpointConstructor } from '../index.js';
const requiredPermissions = [
// 'access:dashboards',
// 'access:exec_levels',
// 'access:management_accounts',
];
const reportEndpoints = () => {
    const { endpoint } = endpointConstructor('/reports');
    return {
        getKPI: endpoint('POST', '/kpi', requiredPermissions),
        getMerchantReportedKpi: endpoint('POST', '/get-merchant-reported-kpi', requiredPermissions),
        getAllReports: endpoint('POST', '/get-reports', requiredPermissions),
        getAttachmentRate: endpoint('POST', '/attachment-rate', requiredPermissions),
        getAzureADToken: endpoint('GET', '/token', requiredPermissions),
        getEligibleSales: endpoint('POST', '/eligible-sales', requiredPermissions),
        getFppSales: endpoint('POST', '/fpp-sales', requiredPermissions),
        getReportsClaimsData: endpoint('GET', '/claims', requiredPermissions),
        getReportsContractsData: endpoint('GET', '/contracts', requiredPermissions),
        getReportsSalesData: endpoint('GET', '/sales', requiredPermissions),
        getReportsUnknownSalesData: endpoint('GET', '/unknown-sales', requiredPermissions),
        getTotalSales: endpoint('POST', '/total-sales', requiredPermissions),
        getHasAvailableImportData: endpoint('POST', '/has-available-import-data', requiredPermissions),
        filterReportsByMerchant: endpoint('POST', '/filter-by-merchant', requiredPermissions),
    };
};
export default reportEndpoints;
