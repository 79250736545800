import { FC, useEffect, useState } from 'react';
import type { UseMutateFunction } from 'react-query';

import { ReportKeys } from '../../constants/report';
import DynamicTable from './DynamicTable';

type col = {
  headerKey: string;
  dataKey: string;
};
type data = { [key: string]: string }[];
interface ReportTableProps {
  report: {
    title: string;
    value: string;
    columns: col[];
  };
  reportPayload: {
    category: string;
    date: {
      start: string;
      end: string;
    };
  };
  data: any;
  isLoading: boolean;
  isSortLoading: boolean;
  setIsSortLoading: (x: boolean) => void;
  getReports: UseMutateFunction<any, string, any, unknown>;
}

const ReportTable: FC<ReportTableProps> = (props: ReportTableProps) => {
  const [reportData, setReportData] = useState<data>([]);
  const { getReports, data, isLoading } = props;

  useEffect(() => {
    if (data) {
      setReportData(data);
    }
  }, [data]);

  const columns = props.report.columns;

  return (
    <>
      <div className="bg-reguard-success text-white font-extrabold mb-2 mt-10 p-1">
        {props.report.value === ReportKeys.ProtectionPlan
          ? 'Protection Plans Overview'
          : 'List of Stores'}
      </div>
      <DynamicTable
        columns={columns}
        data={reportData}
        handleSort={sort =>
          getReports({
            ...props.reportPayload,
            report: props.report.value,
            sort,
          })
        }
        loading={isLoading}
        setIsSortLoading={props.setIsSortLoading}
      />
    </>
  );
};

export default ReportTable;
