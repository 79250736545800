export var USATimeZone;
(function (USATimeZone) {
    USATimeZone[USATimeZone["EAST"] = 0] = "EAST";
    USATimeZone[USATimeZone["CENTRAL"] = 1] = "CENTRAL";
    USATimeZone[USATimeZone["MOUNTAIN"] = 2] = "MOUNTAIN";
    USATimeZone[USATimeZone["PACIFIC"] = 3] = "PACIFIC";
    USATimeZone[USATimeZone["ALASKA"] = 4] = "ALASKA";
    USATimeZone[USATimeZone["HAWAII"] = 5] = "HAWAII";
})(USATimeZone || (USATimeZone = {}));
const UsaTimezoneMap = {
    [USATimeZone.EAST]: -5,
    [USATimeZone.CENTRAL]: -6,
    [USATimeZone.MOUNTAIN]: -7,
    [USATimeZone.PACIFIC]: -8,
    [USATimeZone.ALASKA]: -9,
    [USATimeZone.HAWAII]: -10,
};
const getUsaTzOffset = (timezone) => {
    const offset = UsaTimezoneMap[timezone];
    // daylight savings
    const stdTimezoneOffset = () => {
        const jan = new Date(0, 1);
        const jul = new Date(6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };
    const today = new Date();
    const isDaylightSavings = (today) => {
        return today.getTimezoneOffset() < stdTimezoneOffset();
    };
    return isDaylightSavings(today) ? offset + 1 : offset;
};
export const getUsaOffset = (timezone, useUTC = false) => {
    // minus sign: getTimezoneOffset returns the distance in minutes from UTC
    // so if you are in a GMT +X timezone it will be negative and viceversa
    const localOffset = useUTC ? 0 : -(new Date().getTimezoneOffset() / 60);
    const usTimezoneOffset = getUsaTzOffset(timezone);
    return usTimezoneOffset - localOffset;
};
export const getUSADate = (date, timezone) => {
    date.setHours(date.getHours() + getUsaOffset(timezone));
    return date;
};
export const getDateString = (date) => 
// mm/dd/yyyy
(date &&
    `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
        .getDate()
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`) ||
    '';
export const getDateStringShort = (date) => (date &&
    `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
        .getDate()
        .toString()
        .padStart(2, '0')}/${date.getFullYear().toString().substring(2, 2)}`) ||
    '';
export const formatDate = (date, timezone) => {
    const dateWithUSTimezone = getUSADate(date, timezone);
    return getDateString(dateWithUSTimezone);
};
