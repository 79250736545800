import axios from 'axios';

import config from '../../config';
import getAzureAccessToken from './getAzureAccessToken';

const hashToken = (s: string) =>
  s
    .split('')
    .reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0)
    .toString();

const embedTokenKey = `embed-token-${hashToken('reguard-powerBI-embed-token')}`;
const expirationTokenKey = `expiration-token-${hashToken(
  'reguard-powerBI-token-expiration',
)}`;

const getPowerBIEmbedToken = async () => {
  const accessTokenMS = await getAzureAccessToken();

  const response = await axios.post(
    `${config.powerBI.api_url}/GenerateToken`,
    {
      datasets: [
        {
          id: config.powerBI.datasetId,
        },
      ],
      reports: [
        {
          id: config.powerBI.reportId,
        },
      ],
    },
    { headers: { Authorization: `Bearer ${accessTokenMS}` } },
  );

  const { token, expiration } = response.data;

  localStorage.setItem(embedTokenKey, token);
  localStorage.setItem(expirationTokenKey, expiration);
};

const checkTokenAndUpdatePowerBI = async () => {
  let tokenExpiration = '';

  if (
    localStorage.getItem(expirationTokenKey) &&
    localStorage.getItem(embedTokenKey)
  ) {
    tokenExpiration = localStorage.getItem(expirationTokenKey) as string;
  } else {
    await getPowerBIEmbedToken();
    return localStorage.getItem(embedTokenKey) as string;
  }

  // Get the current time
  const currentTime = Date.now();
  const expiration = Date.parse(tokenExpiration);

  // Update the token if it is about to expired
  if (expiration <= currentTime) {
    console.log('Updating report access token');
    // Generate a new embed token or refresh the user Azure AD access token
    await getPowerBIEmbedToken();
  }
  return localStorage.getItem(embedTokenKey) as string;
};

export default checkTokenAndUpdatePowerBI;
