import { useQuery } from 'react-query';
import initEndpoints from '@reguard/endpoints';
import type { ProtectionPlan } from '@reguard/ui-components';

import { Client, useClient } from './client';
import getParamsString from './getParamsString';

const { getPlans, getPlanById, getPlansForMerchant } =
  initEndpoints().plansEndpoints();

const getPlansSearchConfig = (client: Client, params: any) => ({
  queryKey: ['getPlansForMerchant', params],
  queryFn: () =>
    client(`${getPlansForMerchant.path}${getParamsString(params)}`, {}).then(
      (data: any) => data,
    ),
});

function useGetPlans() {
  const client = useClient();
  const result = useQuery<ProtectionPlan[], Error>(['getPlans'], () =>
    client(getPlans.path, {}),
  );

  return { ...result, data: result.data ?? [] };
}

function useGetPlanById({ planId }: { planId: string }) {
  const client = useClient();
  const result = useQuery(['getPlanById'], () =>
    client(getPlanById.path.replace(':id', planId), {}),
  );

  return { ...result, data: result.data ?? [] };
}

function useGetPlansForMerchant(_params: any) {
  const client = useClient();
  const result = useQuery(getPlansSearchConfig(client, _params));

  return {
    ...result,
    data: (result.data as { _metadata: any; records: ProtectionPlan[] }) ?? [],
  };
}

export { useGetPlanById, useGetPlans, useGetPlansForMerchant };
