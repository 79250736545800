import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { passwordRules } from './constants/regex';
import useDebounce from './hooks/useDebounce';
import { buttonSelectors, textInputFields } from './tests/dataCySelectors';
import { Button } from './Button';
import { TextField } from './TextField';

type SetPassFieldsProps = {
  onSubmit: (arg: any, arg2: any) => Promise<void>;
  showTempPassword?: boolean;
};

const DEBOUNCE_TIMEOUT = 300;

const PasswordDetails = (errors: { password?: string }) => {
  const { t } = useTranslation();
  return (
    <span className={`c1 mb-8 ${errors.password && 'text-reguard-error'}`}>
      {t(
        'password_must_be_at_least_8_characters_long_and_contain_one_character_from_each_of_the_following',
      )}
      <ul className="tw-cst-pf list-disc m-0">
        <li>{t('uppercase_(a,_z)')}</li>
        <li>{t('lowercase_(a,_z)')}</li>
        <li>{t('numeric_(0-9)')}</li>
        <li>{t('special_(!,_%,_@,_#,_etc.)')}</li>
      </ul>
    </span>
  );
};

export const SetPassFields = ({
  onSubmit,
  showTempPassword,
}: SetPassFieldsProps) => {
  const debounced = useDebounce(DEBOUNCE_TIMEOUT);
  const { t } = useTranslation();

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={{
        ...(showTempPassword ? { temporaryPassword: '' } : {}),
        password: '',
        newPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required(t('new_password_is_required') as string)
          .matches(
            passwordRules,
            t("your_password_doesn't_meet_the_requirements") as string,
          )
          .min(8, t('password_must_be_at_least_8_characters') as string),
        newPassword: Yup.string()
          .required(t('please_repeat_the_new_password') as string)
          .when('password', {
            is: () => true,
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              t('please_make_sure_your_passwords_match') as string,
            ),
          }),
        temporaryPassword: showTempPassword
          ? Yup.string()
              .required(t('temporary_password_is_required') as string)
              .min(
                6,
                t('temporary_password_must_be_at_least_6_characters') as string,
              )
          : Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        validateField,
        dirty,
        isValid,
      }) => (
        <form className="grid w-full" onSubmit={handleSubmit}>
          <h4 className="tw-cst-pf text-reguard-indigo mx-0 mt-14 mb-6">
            {t('create_new_password')}
          </h4>
          <span className="b2 mb-6">{t('enter_a_new_password')}</span>

          <PasswordDetails {...errors} />

          {showTempPassword ? (
            <TextField
              name="temporaryPassword"
              data-cy={textInputFields.tempPasswordInputField}
              type="password"
              label={t('temporary_password') as string}
              placeholder={t('type_your_password') as string}
              onChange={e => {
                debounced(() => {
                  handleChange(e);
                });
              }}
              error={Boolean(errors.temporaryPassword)}
              errorMessage={errors.temporaryPassword}
              valid={Boolean(
                values.temporaryPassword && !errors.temporaryPassword,
              )}
              successIconVisible
              errorExclamationIconVisible
            />
          ) : null}
          <TextField
            name="password"
            data-cy={textInputFields.passwordInputField}
            type="password"
            label={t('new_password')}
            placeholder={t('type_your_password') as string}
            onChange={e => {
              debounced(() => {
                handleChange(e);
                values.newPassword && validateField('newPassword');
              });
            }}
            error={Boolean(errors.password)}
            valid={Boolean(values.password && !errors.password)}
            errorMessage={errors.password}
            errorExclamationIconVisible
            successIconVisible
          />
          <TextField
            name="newPassword"
            data-cy={textInputFields.repeatPasswordInputField}
            type="password"
            label={t('repeat_new_password')}
            placeholder={t('repeat_your_password_here') as string}
            onChange={e => {
              debounced(() => handleChange(e));
            }}
            error={Boolean(errors.newPassword)}
            errorMessage={errors.newPassword}
            valid={Boolean(values.newPassword && !errors.newPassword)}
            errorExclamationIconVisible
            successIconVisible
          />

          {status && (
            <span className={`c1 mt-5 text-reguard-error`}>{status}</span>
          )}
          <Button
            isFetching={isSubmitting}
            disabled={
              isSubmitting ||
              !dirty ||
              !isValid ||
              Boolean(
                Object.keys(values).find(
                  key => (values as Record<string, string>)[key] === '',
                ),
              )
            }
            className="mt-10 mx-0 mb-[7.625rem]"
            data-cy={buttonSelectors.savePasswordBtn}
          >
            {t('save_password')}
          </Button>
        </form>
      )}
    </Formik>
  );
};
