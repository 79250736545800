import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ProtectionPlan as MerchantProtectionPlan,
  ProtectionPlanCoverageHighlights,
} from '@reguard/ui-components';

interface ProtectionPlanProps {
  plans: {
    _metadata: any;
    records: MerchantProtectionPlan[];
  };
}

export const ProtectionPlan = ({ plans }: ProtectionPlanProps) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { t } = useTranslation();
  const planData = plans?.records?.find(
    (plan: MerchantProtectionPlan) => plan?.sku === query.get('planId'),
  );
  if (!planData) {
    return <></>;
  }
  const {
    name,
    categories = [],
    period,
    duration,
    termsConditions,
  } = planData || {};
  const getLists = (
    data:
      | MerchantProtectionPlan['coveredDamages']
      | MerchantProtectionPlan['categories']
      | undefined,
    key: 'coveredDamage' | 'category',
  ) => {
    // do not throw error - otherwise it will not work when refreshing the page
    if (!data) {
      return <></>;
    }
    if (data && data.length <= 8) {
      return (
        <div className="col-span-2 sm:col-span-2">
          <ul>
            {data?.map((item: any) => (
              <li key={item?.id} className="group">
                <div className="text-base">{t(item?.[key])}</div>
                <hr className="my-3 group-last:hidden border-reguard-pearl-shade" />
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      const total = data.length;
      const half = Math.ceil(total / 2);
      return (
        <>
          <div className="col-span-2 sm:col-span-1">
            <ul>
              {data?.slice(0, half)?.map((item: any) => (
                <li key={item?.id} className="group">
                  <div className="text-base">{t(item?.[key])}</div>
                  <hr className="my-3 group-last:hidden border-reguard-pearl-shade" />
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <ul>
              {data?.slice(half, total)?.map((item: any) => (
                <li key={item?.id} className="group">
                  <div className="text-base">{t(item?.[key])}</div>
                  <hr className="my-3 group-last:hidden border-reguard-pearl-shade" />
                </li>
              ))}
            </ul>
          </div>
        </>
      );
    }
  };

  return (
    <div className="p-[7.5rem] pt-[10.25rem] sm:p-16 sm:pt-12 overflow-auto">
      <h3 className="tw-cst-pf s2 text-4xl text-reguard-indigo mb-8">{`${
        name ? name[0].toUpperCase() + name.slice(1) : ''
      }`}</h3>
      <h4 className="tw-cst-pf s2 text-2xl text-reguard-indigo mb-4">
        {t('plan_highlights')}
      </h4>

      <div className="mb-6">
        <span className="s1 text-base text-reguard-indigo">
          {t('coverage', { duration, period })}
        </span>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          className="s2 text-base text-reguard-indigo underline ml-3"
          href={termsConditions}
          target="_blank"
        >
          {t('view_terms')}
        </a>
      </div>

      <div className="grid grid-cols-2 gap-x-6 gap-y-8">
        <div className="col-span-2 border border-reguard-inactive2 rounded-lg shadow-card p-8 ">
          <div>
            <h5 className="tw-cst-pf s2 text-lg text-reguard-wintergreen-shade mb-6">
              {t('coverage_highlights')}
            </h5>
            <div className="">
              <ProtectionPlanCoverageHighlights protectionPlan={planData} />
            </div>
          </div>
        </div>

        <div className="col-span-2 border border-reguard-inactive2 rounded-lg	 shadow-card p-8">
          <div>
            <h5 className="tw-cst-pf s2 text-lg text-reguard-indigo mb-6">
              {t('categories')}
            </h5>
            <div className="grid grid-cols-2 gap-x-12 ">
              {getLists(categories, 'category')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
