const config = {
  app: {
    port: import.meta.env.VITE_APP_PORT || 3002,
  },
  api: {
    url: import.meta.env.VITE_API_URL || 'http://localhost:3500',
  },
  powerBI: {
    datasetId: import.meta.env.VITE_POWERBI_DATASET_ID,
    reportId: import.meta.env.VITE_POWERBI_REPORT_ID,
    groupId: import.meta.env.VITE_POWERBI_GROUP_ID,
    api_url: import.meta.env.VITE_POWERBI_API_URL,
    token: import.meta.env.VITE_POWERBI_AUTH_TOKEN,
  },
  ga: {
    trackingCode: import.meta.env.VITE_GA_TRACKING_CODE || '',
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN || '',
    environment: import.meta.env.VITE_SENTRY_ENV || '',
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.1,
  },
  cookieBot: {
    domainId: import.meta.env.VITE_COOKIEBOT_DOMAIN_ID || '',
  },
};

export default config;
