import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainPage } from '@reguard/ui-components';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import config from '../config';
import { embedUrl } from '../constants/powerbi-constants';
import checkTokenAndUpdatePowerBI from '../network/api/getPowerBIEmbedToken';

export const Home = () => {
  const [embedToken, setEmbedToken] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    const generatePowerBIEmbedToken = async () => {
      const token = await checkTokenAndUpdatePowerBI();
      setEmbedToken(token);
    };
    if (!embedToken) {
      generatePowerBIEmbedToken();
    }
  }, [setEmbedToken, embedToken]);

  return (
    <MainPage className="h-screen">
      <div className="container px-12 sm:px-16 pt-32 sm:pt-12 overflow-auto h-full">
        <div className="mb-8 text-2xl text-reguard-indigo">
          <span className="font-semibold">{t('dashboard')}</span>
        </div>

        <div>
          <PowerBIEmbed
            key={embedToken}
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: config.powerBI.reportId,
              groupId: config.powerBI.groupId,
              embedUrl: embedUrl,
              accessToken: embedToken,
              tokenType: models.TokenType.Embed,
              viewMode: models.ViewMode.View,
              permissions: models.Permissions.Read,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  function (event) {
                    console.error('Error rendering PowerBI report!', event);
                  },
                ],
              ])
            }
            cssClassName="h-[600px]"
            getEmbeddedComponent={embeddedReport => {
              (window as any).report = embeddedReport;
            }}
          />
        </div>
      </div>
    </MainPage>
  );
};
