import axios from 'axios';
import jwt_decode from 'jwt-decode';

import config from '../../config';

const getAzureAccessToken = async () => {
  // Get the current time
  const currentTime = Date.now();
  const accessToken: any = localStorage.getItem('accessToken');
  if (!accessToken) {
    await getNewAccessTokenMS();
    return localStorage.getItem('accessToken');
  }
  const { exp }: any = jwt_decode(accessToken);
  const expiration: any = new Date(exp * 1000);

  // Update the token if it is about to expired
  if (expiration <= currentTime) {
    console.log('Updating MS access token');
    await getNewAccessTokenMS();
  }
  return localStorage.getItem('accessToken');
};

const getNewAccessTokenMS = async () => {
  const { data } = await axios.get(`${config.api.url}/reports/token`, {
    headers: { Authorization: `Bearer ${config.powerBI.token}` },
  });

  localStorage.setItem('accessToken', data.token);
};

export default getAzureAccessToken;
