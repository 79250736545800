import { endpointConstructor } from '../index.js';
const testEndpoints = () => {
    const { endpoint } = endpointConstructor('/test');
    return {
        createTestClaim: endpoint('POST', '/claims'),
        createTestUser: endpoint('POST', '/users'),
        deleteTestClaim: endpoint('DELETE', '/claims'),
        deleteTestUser: endpoint('DELETE', '/users'),
        deleteTestUserAddresses: endpoint('DELETE', '/users/addresses'),
        getTestUsers: endpoint('GET', '/users'),
        getTestUser: endpoint('POST', '/users/user'),
        getWebhookClaimOptions: endpoint('GET', '/webhook-claim-options/:contractNumber'),
    };
};
export default testEndpoints;
