import { endpointConstructor } from '../index.js';
const localesEndpoints = () => {
    const { endpoint } = endpointConstructor('/locales');
    return {
        getTranslationFile: endpoint('GET', '/translation-file'),
        getTranslationData: endpoint('GET', '/translation'),
        getTranslationLocaleData: endpoint('GET', '/translation/:lang'),
    };
};
export default localesEndpoints;
