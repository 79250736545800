export const buttonSelectors = {
  addNewAddressBtn: 'addNewAddressBtn',
  addPhoneNumberBtn: 'addPhoneNumberBtn',
  arrowDownBtn: 'arrowDownBtn',
  arrowLeftBtn: 'arrowLeftBtn',
  arrowRightBtn: 'arrowRightBtn',
  cancelBtn: 'cancelBtn',
  closeBtn: 'closeBtn',
  continueBtn: 'continueButton',
  deleteBtn: 'deleteBtn',
  doneBtn: 'doneBtn',
  editPhoneNumberBtn: 'editPhoneNumberBtn',
  editPhotosBtn: 'editPhotosBtn',
  editStepFourBtn: 'editStepFourBtn',
  editStepOneBtn: 'editStepOneBtn',
  editStepThreeBtn: 'editStepThreeBtn',
  editStepTwoBtn: 'editStepTwoBtn',
  fileClaimBtn: 'fileClaimBtn',
  finishClaimBtn: 'finishClaimBtn',
  loadAllBtn: 'loadAllBtn',
  loginButton: 'loginButton',
  myPlanBtn: 'myPlanBtn',
  receiptUploadBtn: 'receiptUploadBtn',
  removeBtn: 'removeBtn',
  resendCodeBtn: 'resendCodeBtn',
  saveAddressBtn: 'saveAddressBtn',
  saveAndCloseBtn: 'saveAndCloseBtn',
  saveChangesBtn: 'saveChangesBtn',
  saveDraftBtn: 'saveDraftBtn',
  savePasswordBtn: 'savePasswordBtn',
  seeClaimDetailsBtn: 'seeClaimDetailsBtn',
  seeLessBtn: 'seeLessBtn',
  seeMoreBtn: 'seeMoreBtn',
  sendEmailBtn: 'sendEmailBtn',
  submitBtn: 'submitBtn',
  uploaderBtn: 'uploaderBtn',
  uploadPhotosBtn: 'uploadPhotosBtn',
  viewClaimBtn: 'viewClaimBtn',
  viewDetailsBtn: 'viewDetailsBtn',
};

export const cardsSelectors = {
  claimCard: 'claimCard',
  planCard: 'planCard',
  productCard: 'productCard',
};

export const componentNameSelector = {
  chatHeaderComponent: 'chatHeaderComponent',
  claimPhotosComponent: 'claimPhotosComponent',
  claimPhotosModal: 'claimPhotosModal',
  setPassComponent: 'setPassComponent',
  unsubscribeComponent: 'unsubscribeComponent',
  verifyCodeComponent: 'verifyCodeComponent',
  verifyEmailComponent: 'verifyEmailComponent',
};

export const mainPageSelectors = {
  activePlansText: 'activePlans',
  mainPage: 'mainPage',
};

export const navigationLinksSelectors = {
  cancel: 'cancel',
  logoutLink: 'logoutLink',
};

export const textAreaSelectors = {
  textArea: 'textArea',
};

export const textInputFields = {
  address1InputField: 'address1InputField',
  address2InputField: 'address2InputField',
  cityInputField: 'cityInputField',
  emailInputField: 'emailInputField',
  passwordInputField: 'passwordInputField',
  phoneNumberInputField: 'phoneNumberInputField',
  postalCodeInputField: 'postalCodeInputField',
  repeatPasswordInputField: 'repeatPasswordInputField',
  stateInputField: 'stateInputField',
  tempPasswordInputField: 'tempPasswordInputField',
};
