import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import type { ProtectionPlan, UserRoleType } from '@reguard/ui-components';

import { Routes } from '../../constants/routes';
import { useMyTrainingUser } from '../../network/api/training';
import { ReactComponent as DashboardSVG } from '../assets/dashboard.svg';
import { ReactComponent as PlansSVG } from '../assets/protectionPlans.svg';
import { ReactComponent as SubmenuArrowSVG } from '../assets/submenuArrow.svg';
import { ReactComponent as TrainingPortalSVG } from '../assets/training_portal.svg';

const activeRouteStyle = 'bg-reguard-violet rounded-lg b1';
const baseStyle =
  'flex items-center b1b text-reguard-pearl-tint p-2 w-full text-left max-w-[14.75rem]';
interface MenuProps {
  plans: {
    _metadata: any;
    records: ProtectionPlan[];
  };
  userRole: UserRoleType;
}

interface LeadershipMenuProps extends Omit<MenuProps, 'userRole'> {
  planSubMenuVisible: boolean;
  setPlanSubMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isReportsOpen: boolean;
  setReportOpen: React.Dispatch<React.SetStateAction<boolean>>;
  urlPlanSku: string | null;
  showTrainingPortal: boolean;
}

const LeadershipRoutes = ({
  planSubMenuVisible,
  setPlanSubMenuVisible,
  plans,
  urlPlanSku,
  showTrainingPortal,
}: LeadershipMenuProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <Link
        to={Routes.HOME}
        className={`${baseStyle} ${
          location.pathname === Routes.HOME ? activeRouteStyle : ''
        }`}
      >
        <DashboardSVG className="mr-2" />
        {t('dashboard')}
      </Link>

      <div className="protection-plan-submenu">
        <button
          className={`${baseStyle}`}
          onClick={() => setPlanSubMenuVisible(!planSubMenuVisible)}
        >
          <PlansSVG className="mr-2" />
          <div className="flex flex-row w-full justify-between items-center ml-[3px]">
            <div>{t('protection_plans')}</div>
            <SubmenuArrowSVG
              className={`${planSubMenuVisible ? '' : 'rotate-180'}`}
            />
          </div>
        </button>
      </div>

      {planSubMenuVisible && plans?.records?.length > 0 && (
        <>
          {plans?.records
            ?.sort((a, b) => (b.name > a.name ? -1 : 1))
            .map(plan => (
              <Link
                key={plan?.id}
                to={`${Routes.PROTECTION_PLANS}?&planId=${plan?.sku}`}
                className={`${baseStyle} ${
                  urlPlanSku === plan?.sku ? activeRouteStyle : ''
                }`}
              >
                <div className="ml-8">{plan?.name}</div>
              </Link>
            ))}
        </>
      )}
      <Link
        to={Routes.FINANCIAL_REPORTS}
        className={`${baseStyle} ${
          location.pathname === Routes.FINANCIAL_REPORTS ? activeRouteStyle : ''
        }`}
      >
        <DashboardSVG className="mr-2" />
        Financial Report
      </Link>

      {showTrainingPortal && (
        <Link
          to={Routes.TRAINING_PORTAL}
          className={`${baseStyle} ${
            location.pathname === Routes.TRAINING_PORTAL ? activeRouteStyle : ''
          }`}
        >
          <TrainingPortalSVG className="mr-2" />
          {t('training_portal')}
        </Link>
      )}
    </>
  );
};

const Menu = ({ plans }: MenuProps) => {
  const [urlParams] = useSearchParams();
  const location = useLocation();
  const [planSubMenuVisible, setPlanSubMenuVisible] = useState(
    location.pathname.includes(Routes.PROTECTION_PLANS),
  );
  const [isReportsOpen, setReportOpen] = useState(
    location.pathname.includes(Routes.REPORTS),
  );
  const urlPlanSku = urlParams.get('planId');
  const { data: trainingPortalData } = useMyTrainingUser({ enabled: false });

  const showTrainingPortal = Boolean(trainingPortalData?.login);

  return (
    <>
      <LeadershipRoutes
        planSubMenuVisible={planSubMenuVisible}
        setPlanSubMenuVisible={setPlanSubMenuVisible}
        plans={plans}
        isReportsOpen={isReportsOpen}
        setReportOpen={setReportOpen}
        urlPlanSku={urlPlanSku}
        showTrainingPortal={showTrainingPortal}
      />
    </>
  );
};

export default Menu;
