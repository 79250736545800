import { useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Button, dateFormat } from '@reguard/ui-components';
import { ControlledMenu, MenuButton, useClick } from '@szhsin/react-menu';
import { t } from 'i18next';

import '@szhsin/react-menu/dist/index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface FilterProps {
  setDate: (data: any) => any;
  className?: string;
}
const Filter = (props: FilterProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const anchorProps = useClick(isOpen, setOpen);
  const start = new Date('2023-05-1');
  const [date, setDate] = useState<any>([
    {
      startDate: start,
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [selectedDate] = date;
  return (
    <div className={props.className}>
      <MenuButton
        className="px-4 text-base leading-[1.125rem] semibold text-reguard-indigo text-center"
        ref={ref}
        {...anchorProps}
      >
        <br />
        {dateFormat(selectedDate.startDate, 'MM/DD/YYYY')} –{' '}
        {dateFormat(selectedDate.endDate, 'MM/DD/YYYY')}
      </MenuButton>
      <ControlledMenu
        direction="left"
        state={isOpen ? 'open' : 'closed'}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        <DateRangePicker
          onChange={item => setDate([item.selection])}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={date}
          direction="horizontal"
          maxDate={new Date()}
          rangeColors={['#01BD95']}
          inputRanges={[]}
        />
        <div className="pr-2 flex flex-row-reverse">
          <Button
            onClick={() => {
              props.setDate({
                startDate: date[0].startDate,
                endDate: date[0].endDate,
              });
              setOpen(false);
            }}
          >
            {t('filter')}
          </Button>
        </div>
      </ControlledMenu>
    </div>
  );
};

export default Filter;
