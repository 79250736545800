import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import {
  ErrorPage,
  LoaderGlobal,
  PublicRoutesContainer,
  SetPass,
  Toast,
  useGoogleAnalytics,
  User,
  VerifyEmail,
} from '@reguard/ui-components';
import { i18nInit } from '@reguard/ui-components';
import * as Sentry from '@sentry/react';

import { Routes as ROUTES } from './constants/routes';
import { useGetPlansForMerchant } from './network/api/plans';
import { useMyTrainingUser } from './network/api/training';
import {
  useCreateUser,
  useLogout,
  useMyUser,
  useRequestVerifyEmail,
  useSetMerchantPassword,
  useVerifyEmail,
} from './network/api/users';
import { Header } from './views/shared/Header';
import classes from './App.module.css';
import config from './config';
import {
  FinancialReport,
  Home,
  Login,
  MobileHeader,
  ProtectionPlan,
  Sidebar,
  TrainingPortal,
} from './views';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const i18n = i18nInit();

const LoggedRoutes = ({ userData }: { userData: User }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isFetching: isFetchingTraining } = useMyTrainingUser({});

  const { mutate: logout } = useLogout();

  const { data: plans } = useGetPlansForMerchant({
    page: '1',
    pageLength: '10',
  });

  const logoutHandler = () => {
    logout({});
    navigate('/');
  };

  return (
    <div
      className={`${classes.appContainer} auto-rows-auto h-screen bg-reguard-pearl-tint relative`}
    >
      <LoaderGlobal isLoading={isFetchingTraining} />
      <MobileHeader
        user={userData}
        onLogout={logoutHandler}
        userRole={userData?.role}
        plans={plans}
      />
      <Sidebar
        user={userData}
        onClick={logoutHandler}
        userRole={userData?.role}
        plans={plans}
      />

      <SentryRoutes>
        <Route path="/" element={<Outlet />} />
        <Route index element={<Home />} />
        <Route
          path={ROUTES.PROTECTION_PLANS}
          element={<ProtectionPlan plans={plans} />}
        />
        <Route path={ROUTES.TRAINING_PORTAL} element={<TrainingPortal />} />
        <Route
          path={ROUTES.FINANCIAL_REPORTS}
          element={<FinancialReport merchants={userData.merchants} />}
        />

        <Route
          path="not-found"
          element={
            <ErrorPage
              callToAction={() => navigate('/')}
              ctaLabel={t('back_to_home') as string}
            />
          }
        />

        <Route path={ROUTES.SET_PASSWORD} element={<Navigate to="/" />} />
        <Route path="*" element={<Navigate to="not-found" />} />
      </SentryRoutes>
    </div>
  );
};

const PublicRoutes = () => {
  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: setMerchantPassword } = useSetMerchantPassword();
  return (
    <PublicRoutesContainer>
      <Header></Header>

      <div className="bg-white lg:max-w-[37.5rem] lg:mx-auto">
        <SentryRoutes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Login />} />
            <Route
              path={ROUTES.FORGOT}
              element={
                <VerifyEmail
                  useVerifyEmail={useVerifyEmail}
                  useRequestVerifyEmail={useRequestVerifyEmail}
                />
              }
            />
            <Route
              path="set-password/:token"
              element={
                <SetPass
                  createUser={createUser}
                  setUserPassword={setMerchantPassword}
                  isMerchant={true}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </SentryRoutes>
      </div>
    </PublicRoutesContainer>
  );
};

function App() {
  const { data: userData, isLoading, isFetching } = useMyUser();
  const isLogged = userData && userData?.email;
  useGoogleAnalytics(config.ga.trackingCode);

  const language = userData.languagePref;
  const changeLanguage = async (language: string) => {
    const i18nInstance = await i18n;
    i18nInstance.changeLanguage(language);
  };
  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]);
  return (
    <>
      <LoaderGlobal isLoading={isLoading || isFetching} />
      {isLoading ? null : isLogged ? (
        <LoggedRoutes userData={userData} />
      ) : (
        <PublicRoutes />
      )}
      <Toast />
    </>
  );
}

export default App;
