import { FC, useState } from 'react';
import { t } from 'i18next';
import * as _ from 'lodash';

import { ReactComponent as ArrowDownSVG } from '../assets/arrow_black.svg';

interface Sort {
  field: string;
  direction: string;
}
interface DynamicTableProps {
  data: any;
  columns: {
    headerKey: string;
    dataKey: string;
    getDataKey?: any;
    conditionKey?: string;
    canSort?: boolean | undefined;
  }[];
  handleSort?: (data: Sort) => void;
  loading?: boolean;
  setIsSortLoading: (x: boolean) => void;
}

type Props = {
  handleSort?: (data: Sort) => void;
  col: {
    headerKey: string;
    dataKey: string;
    getDataKey?: any;
    conditionKey?: string | undefined;
    canSort?: boolean | undefined;
  };
  setIsSortLoading: (x: boolean) => void;
};

const isFloat = n => {
  return n === +n && n !== (n | 0);
};

const TableHeader = ({ col, handleSort, setIsSortLoading }: Props) => {
  const [isAsc, setIsAsc] = useState(false);

  const handleSorting = (data: any, handleSort: any) => {
    setIsSortLoading(true);
    handleSort(data);
  };

  return (
    <th className={`p-2`}>
      <div
        className={`flex items-center ${col.canSort && 'cursor-pointer'}`}
        onClick={() => {
          setIsAsc(prev => !prev);
          handleSort &&
            handleSorting(
              {
                field: col.dataKey,
                direction: !isAsc ? 'asc' : 'desc',
              },
              handleSort,
            );
        }}
      >
        {col.headerKey}
        {col.canSort && (
          <>
            <ArrowDownSVG
              className={isAsc ? 'rotate-180 ml-[1rem]' : 'ml-[1rem]'}
            ></ArrowDownSVG>
          </>
        )}
      </div>
    </th>
  );
};
const DynamicTable: FC<DynamicTableProps> = ({
  data,
  columns,
  handleSort,
  setIsSortLoading,
}: DynamicTableProps) => {
  return Array.isArray(data) ? (
    <table className="permissions-table w-full">
      <thead>
        <tr className=" permissions-table_header overflow-hidden semibold text-lg mb-4">
          {columns.map(col => (
            <TableHeader
              key={col.headerKey}
              col={col}
              handleSort={handleSort}
              setIsSortLoading={setIsSortLoading}
            />
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item: any, dataIndex) => {
          if (item.label) {
            item.label = t(item.label);
          }
          return (
            <tr
              key={dataIndex}
              className={`permissions-table_row items-center overflow-hidden mb-3 md:mb-0 even:bg-reguard-pearl`}
            >
              {columns.map((col, colIndex) => {
                const key =
                  col.getDataKey &&
                  col.conditionKey &&
                  col.getDataKey(item[col.conditionKey]);
                if (isFloat(item[col.dataKey])) {
                  item[col.dataKey] = _.round(item[col.dataKey], 2);
                }

                if (isFloat(item[key])) {
                  item[key] = _.round(item[key], 2);
                }
                return (
                  <td
                    key={`${dataIndex}-${colIndex}`}
                    className="permissions-table_column  "
                  >
                    <div className="break-normal p-2">
                      {key ? item[key] : item[col.dataKey]}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : null;
};

export default DynamicTable;
