import { endpointConstructor } from '../index.js';
const userEndpoints = () => {
    const { endpoint } = endpointConstructor('/user');
    return {
        create: endpoint('POST'),
        createMerchantUser: endpoint('POST', '/merchant'),
        find: endpoint('GET'),
        findAll: endpoint('GET', '/all'),
        sendResetPasswordEmail: endpoint('POST', '/forgot'),
        setPassword: endpoint('PATCH', '/set-password'),
        setPasswordMerchant: endpoint('PATCH', '/set-password-merchant'),
        changePassword: endpoint('PATCH', '/change-password'),
        requestEmailChange: endpoint('POST', '/change-email'),
        changeEmail: endpoint('PATCH', '/email'),
        requestPhoneNumberChange: endpoint('POST', '/change-phone'),
        changePhoneNumber: endpoint('PATCH', '/phone'),
        updateRole: endpoint('PATCH', '/role'),
        updateContractAddress: endpoint('PATCH', '/contract/address'),
        updateContractPhone: endpoint('PATCH', '/contract/phone'),
        updateCustomerAddress: endpoint('PATCH', '/address'),
        addCustomerAddress: endpoint('POST', '/address'),
        getCustomerAddresses: endpoint('GET', '/address'),
        getMapBoxAddress: endpoint('GET', '/address/mapbox'),
        deleteUser: endpoint('DELETE'),
        requestVerifyEmail: endpoint('POST', '/verify-email'),
        verifyEmail: endpoint('PATCH', '/verify-email'),
        requestVerifyEmailMerchant: endpoint('POST', '/merchant/verify-email'),
        verifyEmailMerchant: endpoint('PATCH', '/merchant/verify-email'),
        updatePrefLanguage: endpoint('PATCH', '/pref-language'),
        testReset: endpoint('POST', '/test-reset')
    };
};
export default userEndpoints;
